import { connect } from 'react-redux';
import { CardHeader, Card, CardTitle, CardBody } from "reactstrap";

import DocumentsImage from '../assets/DocumentsImage.svg'
import ConfirmedImage from 'assets/img/misc/ConfirmedImage.svg'

import { Link } from 'react-router-dom';

import cardStyles from '../../_styles/cards'

const Dashboard = ({ertc_pct_documents}) => {

    const finished = ertc_pct_documents === 100;

    return (
        <Card style={cardStyles} className={finished ? 'main-card border-success' : 'main-card border'}>
            
            <CardHeader>
                <CardTitle className="mb-0">Documents <span className="float-right">({ertc_pct_documents}%)</span></CardTitle>
            </CardHeader>
            
            <CardHeader className="bg-secondary image-container" style={finished ? {maxHeight: 145} : {paddingTop: 19, paddingBottom: 19}}>
                <div className="text-center">
                    <img src={finished ? ConfirmedImage : DocumentsImage} alt="..." className={`w-50 ${finished ? 'ml-7': ''}`} />
                </div>
            </CardHeader>

            <CardHeader>
                {finished ? (
                    <p className="text-sm mb-0">You're all set here! Your documents have been uploaded and are now under review. There is no further action that you need to take in this area of your case at this moment.</p>
                ) : (
                    <p className="text-sm mb-0">To get your Tax Credits, we need certain documents like SBA forms, Payroll Summaries, etc. You may upload copies of your files by clicking the blue button below.</p>
                )}
            </CardHeader>
            
            <CardBody>
                {finished ? (
                    <button disabled={true} className="btn btn-success btn-block btn-lg"><i className="fas fa-check mr-2 " /> Finished</button>
                ) : (
                    <Link to="/ertc/documents" className="btn btn-info btn-block btn-lg"><i className="fas fa-upload mr-2 " /> Get Started</Link>
                )}
            </CardBody>
        </Card>
    )

}

const mapStateToProps = state => {
	return {
	    ertc_pct_documents: state.system.ertc_pct_documents,
	};
};

export default connect(mapStateToProps, '')(Dashboard);