import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Container } from "reactstrap";

import CreditCounselingCard from './Card'

const Dashboard = ({ertc_pct_client_services}) => (
    <Container fluid>

        <Helmet>
            <title>{`Checklist`}</title>
            <meta name="description" content="Checklist" />
        </Helmet>

        <CreditCounselingCard />
        
    </Container>
)

const mapStateToProps = state => {
	return {
        ertc_pct_client_services: state.system.ertc_pct_client_services,
	};
};

export default connect(mapStateToProps, '')(Dashboard);