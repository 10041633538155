import { connect } from 'react-redux';

import NavbarMobile from './NavbarMobile'
import NavbarDesktop from './NavbarDesktop'


const Navbar = ({showMobileNav, toggleSidenav}) => {

	return showMobileNav ? (
		<NavbarMobile toggleSidenav={toggleSidenav} />
	) : (
		<NavbarDesktop />
	)

}

const mapStateToProps = state => {
	return {
	    device: state.device.info,
	};
};

export default connect(mapStateToProps, '')(Navbar);