import { Helmet } from 'react-helmet';
import { Container } from "reactstrap";

import BaseInfo from './BaseInfo';
import Password from './Password';

const AccountSettings = () => {

    return (
        <Container fluid>

            <Helmet>
                <title>Settings</title>
                <meta name="description" content="Settings" />
            </Helmet>
                
            <div className="mt-4">
                <BaseInfo />
                <Password />
            </div>

        </Container>
    )

}

export default AccountSettings;