/*
Documentation

this function needs to run when the app loads
when it is finished it returns true if all went well or false if it did not

*/

import { setViewingUser } from 'store/functions/auth/auth';
import { setDeviceListener } from 'store/functions/device/device'
import { setSelectedCase } from 'store/functions/system/system'

import _cases from '_functions/cases';

import Cookies from 'universal-cookie';
 



const init = () => new Promise (async resolve => {

    // prevent accidental dropping of files into the system
    window.addEventListener("dragover",function(e){ e.preventDefault(); },false);
    window.addEventListener("drop",function(e){ e.preventDefault(); },false);
    // close all user tabs on logout

    // listen for changes in screen size, set device, etc
    setDeviceListener()

    let errors = 0;

    const tryLoad = async (tries = 0) => {

        if(window.location.href.includes('/errors/')) return resolve(true);

        errors = 0;

        const cookies = new Cookies();
        const selected_case = cookies.get('selected_case');

        let promiseArray = [
            setViewingUser(),
        ]

        if(selected_case) promiseArray.push(_cases.findById(selected_case));

        //run all promises that need to fire before the app loads here
        const values = await Promise.all(promiseArray);

        if(!values[0].success) errors++;

        // if we dont have an error resolve success
        if(!errors) {
            if(values[1] && values[1].data) setSelectedCase(values[1].data)
            return resolve(true);
        }
        // if we have tried to load over 3 times resolve error
        if(tries > 2) return resolve(false);

        setTimeout(() => tryLoad(tries + 1), 2000);

    }

    tryLoad();

})

export default init