import { connect } from 'react-redux';

import NavbarMobile from './NavbarMobile'

const Navbar = ({showMobileNav, toggleSidenav}) => {

	return showMobileNav ? (
		<NavbarMobile toggleSidenav={toggleSidenav} />
	) : <></>

}

const mapStateToProps = state => {
	return {
	    device: state.device.info,
	};
};

export default connect(mapStateToProps, '')(Navbar);