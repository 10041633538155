/*
Documentation

this page handles log in for a user
on successful login we set a cookie architeck_uid
when we send off to see if the user is logged in this is
passed along as well to prevent cors cookie issues

*/

import keys from 'keys';
import React from "react";
import { Helmet } from 'react-helmet';
import { Link, Redirect } from "react-router-dom";
import { CardFooter, CardHeader, CardTitle, Card, CardBody, Col, Container, Form, FormGroup, Input, Row } from "reactstrap";
import _companies from '_functions/companies';
import { getCompanyLink } from 'utils/helpers';
import { getUrlParameter } from 'utils/urls'

import ModalForgotCompany from './ModalForgotCompany';
import ModalToggler from 'components/functional/modals/Toggler'

class Login extends React.Component {

	state = {
        shouldRedirect: '/login/biz head law',
        reset: false,
        email: '',
        password: ''
    };

	//on keypress of enter simulate the form being submitted for better UI
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			document.getElementById("loginButton").click();
		}
	}

	onLogin = async (e) => {

        e.preventDefault()

        this.setState({error: null})

		const { company_name } = this.state;
		if(!company_name) return this.setState({error: 'Please add your company name.'});

        //validate the users password
		const foundCompany = await _companies.findByName(company_name);

        if(foundCompany.data) {
            const redirect = getUrlParameter('redirect');
            const query = redirect ? `?redirect=${redirect}` : ''
            this.setState({shouldRedirect: `/login/${getCompanyLink(foundCompany.data)}${query}`})
        } else {
            this.setState({error: `Please enter a valid company name`})
        }

    }

    componentDidMount = () => {
        const input = document.getElementById('company-input');
        if(input) input.focus();
    }

	render() {

        const { error, shouldRedirect, company_name } = this.state
		if(shouldRedirect) return <Redirect to={shouldRedirect} />

		return (

		<>

			<Helmet>
				<title>Login</title>
				<meta name="description" content="Login" />
			</Helmet>

            <div className="header  py-7 py-lg-8 pt-lg-9">
                <Container>
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">

                                <img style={{width: 250}} alt="..."  src={keys.LOGO_WHITE} />
                                <p className="font-weight-bold mt-3">Account Log In</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

			<Container className="mt--9 pb-5">
				<Row className="justify-content-center">
					<Col lg="5" md="7">

                        <Form role="form">

                            <Card className="bg-white border-0 mb-0 z-depth-2" >

                                <CardHeader>
                                    <CardTitle className="mb-0">Sign in To Your Account</CardTitle>
                                </CardHeader>

                                <CardBody >

									<FormGroup>
                                        <label className="form-control-label">What is the company you are logging in to?</label>
                                        <Input
                                            id="company-input"
                                            name="company_name"
                                            onKeyDown={this._handleKeyDown}
                                            placeholder="Your Company"
                                            type="text"
                                            value={company_name	 || ''}
                                            onChange={(e) => this.setState({company_name: e.target.value})}
                                        />
									</FormGroup>

                                </CardBody>

                                <CardFooter className="text-center pt-1">
                                    {error && <p className="text-sm text-danger font-weight-bold mb--2 mt-3">{error}</p>}
                                    <button id="loginButton" onClick={this.onLogin} className="my-4 btn-block btn btn-success">
                                        Sign in
                                    </button>
                                </CardFooter>
                            </Card>

                        </Form>

                        <Row className="mt-4">
							<Col xs="6" className="text-left">
                                <p className="text-sm font-weight-bold">
                                    <ModalToggler component={ModalForgotCompany}>
                                        <span className="cursor-pointer text-white"> Forgot Your Company?</span>
                                    </ModalToggler>
                                </p>

							</Col>
							<Col xs="6" className="text-right">
								<Link to="/register" >
                                    <p className="text-sm font-weight-bold text-white">Register</p>
								</Link>
							</Col>
						</Row>

					</Col>
				</Row>
			</Container>
		</>
		);
	}
}

export default Login

