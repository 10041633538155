import { connect } from 'react-redux';
import { CardHeader, Card, CardTitle, CardBody } from "reactstrap";

import CreditCounselingImage from '../assets/CreditCounselingImage.svg'

import A from 'components/markup/links/A'

import cardStyles from '../../_styles/cards'

import { toggleAlertBS } from 'store/functions/system/system';
import onDownloadDocument from '_functions/documents/download'

const Dashboard = ({selected_case}) => {
    
    return (
        <Card style={cardStyles} className="main-card mb-4">
            
            <CardHeader>
                <CardTitle className="mb-0">Download Report <span className="float-right"></span> </CardTitle>
            </CardHeader>
            
            <CardHeader className="bg-secondary" style={{maxHeight: 142, paddingTop: 21, paddingBottom: 21}}>
                <div className="text-center">
                    <img src={CreditCounselingImage} alt="..." className="w-50" />
                </div>
            </CardHeader>

            <CardHeader>
                <p className="text-sm mb-0">Click the link below to download your signed report. <b className="text-underline">YOU MUST SIGN THIS BY HAND</b>, not electronically. When you are done come back here to upload the signed copy.</p>
            </CardHeader>

            <CardBody>
                {selected_case.final_report_combined ? (
                        <button  onClick={() => onDownloadDocument(selected_case.final_report_combined)} className="btn btn-success btn-block btn-lg">
                        <i className="fas fa-download mr-2 " /> Click To Download
                    </button>
                ):  selected_case.final_package_link ? (
                     <A href={selected_case.final_package_link} className="btn btn-success btn-block btn-lg">
                     <i className="fas fa-download mr-2 " /> Click To Download
                 </A>
                ) : (
                    <button onClick={() => toggleAlertBS('info', 'To download this document please reach out to support via email at operations@bizheadlaw.com')} className="btn btn-success btn-block btn-lg">
                        <i className="fas fa-download mr-2 " /> Click To Download
                    </button>
                )}
               
            </CardBody>
        </Card>
    )

}

const mapStateToProps = state => {
	return {
        selected_case: state.system.selected_case,

	};
};

export default connect(mapStateToProps, '')(Dashboard);