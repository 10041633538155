import SelectCase        from '../pages/SelectCase';

const routes = () =>  [
    {
        path: "/",
        component: SelectCase,
    },
]

export default routes;
