import { useState } from 'react'

const ExpandableImage = ({children}) => {

    const [expanded, setExpanded] = useState(false);

    return (
        <div
            onClick={() => setExpanded(!expanded)}
            style={
                expanded ? {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 99999,
                    overflow: 'auto',
                    cursor: 'pointer'
                } : {
                    cursor: 'pointer'
                }
            }
        >
            {children}
        </div>
    )

}

export default ExpandableImage;