/*
Documentation

this should wrap all logged in routes
runs logic to redirect users if they are not logged in

*/


import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { getCompanyLink } from 'utils/helpers';

const LoggedInRoute = ({viewing_user, children, company}) => {

    const [ loaded, setLoaded ] = useState(false);

    useEffect(() => {
        //if the user is not logged in redirect them
        if(!viewing_user) {
            // store.dispatch({ type: actionTypes.SET_LOGIN_REDIRECT, payload: window.location.pathname + window.location.search });
            const link = company && company._id ? getCompanyLink(company) : ''
            return window.location.href = `/login/${link}?redirect=${window.location.pathname}`
            // return setRedirect (`/auth/login?redirect=${window.location.pathname}`)
        }

        setLoaded(true)

    }, [viewing_user, company])

    if(!loaded) return <div />

    return children

}

const mapStateToProps = state => {
	return {
        company: state.company.company,
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(LoggedInRoute);
