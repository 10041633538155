
import { Helmet } from 'react-helmet';

import { Container } from "reactstrap";

import ClientServices from './Card'

const Dashboard = () => (
    <Container fluid>

        <Helmet>
            <title>{`Checklist`}</title>
            <meta name="description" content="Checklist" />
        </Helmet>

        <ClientServices />

    </Container>
)

export default Dashboard;