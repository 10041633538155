import React, { useState, useCallback, useEffect } from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Form, FormGroup, Container } from 'reactstrap';

import Circle from 'components/markup/loading/Circle';

import _contacts from '_functions/contacts';
import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

const OptOutEmail = ({match}) => {

    const [err, setErr] = useState(false);
    const [opt_out_email, setOptOutEmail] = useState(false);
    const [opt_out_text, setOptOutText] = useState(false);
    const [contact, setContact] = useState(false);

    const onInputToggle = useCallback((stateName) => {
        if(stateName === 'opt_out_email') {
            setOptOutEmail(!opt_out_email)
        } else {
            setOptOutText(!opt_out_text)
        }
    }, [opt_out_email, opt_out_text])


    const onSubmit = useCallback(async () => {
        toggleStandardLoader(true)
        const updated = await _contacts.updateOptOut(contact._id, { opt_out_email, opt_out_text })
        toggleStandardLoader(false)

        if(updated.success) {
            toggleAlertBS(false, 'Your preferences have been updated.')
        } else {
            toggleAlertBS(true, 'You have been given an incorrect link to this page.')
        }

    }, [contact, opt_out_email, opt_out_text])

    const fetchContact = useCallback(async () => {
        const data = await _contacts.fetchOptOut(match.params.identifier)
        if(data.data && data.data._id) {
            setContact(data.data)
            setOptOutEmail(data.data.opt_out_email)
            setOptOutText(data.data.opt_out_text)
        } else {
            setErr(true)
        }
    }, [match.params.identifier])

    useEffect(() => {
        fetchContact()
    }, [fetchContact])

    if(err) return <p className="text-center mt-4">**The link you are currently on is incorrect**</p>
    if(!contact) return <div className="py-6"><Circle /></div>

    return (
        <Container className="pt-5" style={{maxWidth: 450}}>

            <h2>Messaging Preferences</h2>
            <p className="text-sm">To manage the emails and texts you receive from Fairmax Law change your settings below.</p>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">My Settings: {contact.identifier}</CardTitle>
                </CardHeader>

                <CardBody>
                    <Form>

                        <FormGroup>
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="opt-out-email"
                                    type="checkbox"
                                    checked={!opt_out_email}
                                    onClick={() => onInputToggle('opt_out_email')}
                                    onChange={() => {}}
                                />
                                <label className="custom-control-label" htmlFor="opt-out-email">
                                    <i className="fas fa-envelope text-success mr-2" /> Receive Email Communication
                                </label>
                            </div>
                        </FormGroup>

                        <FormGroup className="mb-0">
                            <div className="custom-control custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id="opt-out-phone"
                                    type="checkbox"
                                    checked={!opt_out_text}
                                    onClick={() => onInputToggle('opt_out_text')}
                                    onChange={() => {}}
                                />
                                <label className="custom-control-label" htmlFor="opt-out-phone">
                                    <i className="fas fa-phone text-success mr-2" /> Receive Phone Communication
                                </label>
                            </div>
                        </FormGroup>

                    </Form>
                </CardBody>

                <CardFooter className="text-right">
                    <button onClick={onSubmit} className="btn btn-outline-warning">Update Settings</button>
                </CardFooter>

            </Card>

            <p className="text-sm mt-3">** Note that if you are a current client you may still receive some reminders and notifications regardless of the settings above</p>

        </Container>
    )

}



export default OptOutEmail;
