import { connect } from 'react-redux';
import { CardHeader, Card, CardTitle, CardBody } from "reactstrap";

import { Link } from 'react-router-dom';

import CreditCounselingImage from '../assets/CreditCounselingImage.svg'
import ConfirmedImage from 'assets/img/misc/ConfirmedImage.svg'

import cardStyles from '../../_styles/cards'
import A from 'components/markup/links/A'
import { toggleAlertBS } from 'store/functions/system/system';

import onDownloadDocument from '_functions/documents/download'

const Dashboard = ({bk_pct_credit_counseling, selected_case}) => {

    const finished = bk_pct_credit_counseling === 100;
    
    return (
        <Card style={cardStyles} className={finished ? 'main-card border-success' : 'main-card border'}>
            
            <CardHeader>
                <CardTitle className="mb-0">Final Report <span className="float-right">0%</span> </CardTitle>
            </CardHeader>
            
            <CardHeader className="bg-secondary image-container" style={{maxHeight: 142}}>
                <div className="text-center">
                    <img src={finished ? ConfirmedImage : CreditCounselingImage} alt="..." className={`w-50 ${finished ? 'ml-7': ''}`} />
                </div>
            </CardHeader>

            <CardHeader>
                {finished ? (
                    <p className="text-sm mb-0">You're all set here! Your documents will be submitted to the IRS shortly and we'll notify you when you tax credit is ready to be sent to you.</p>
                 
                    ) : (
                    <p className="text-sm mb-0">Your documents are about ready to be sent to the IRS! All that's left is to click the button below, download a copy of your final report, and then upload it here after you have signed it.</p>
                )}
            </CardHeader>
            
            <CardBody>
                {/* {finished ? (
                    <button disabled={true} className="btn btn-success btn-block btn-lg"><i className="fas fa-check mr-2"  /> Finished</button>
                ) : (
                    <Link to="/ertc/final" className="btn btn-success btn-block btn-lg">Get Started</Link>
                )} */}
                {selected_case.final_report_combined ? (
                        <button  onClick={() => onDownloadDocument(selected_case.final_report_combined)} className="btn btn-success btn-block btn-lg">
                        <i className="fas fa-download mr-2 " /> Click To Download
                    </button>
                ):  selected_case.final_package_link ? (
                     <A href={selected_case.final_package_link} className="btn btn-success btn-block btn-lg">
                     <i className="fas fa-download mr-2 " /> Click To Download
                 </A>
                ) : (
                    <button onClick={() => toggleAlertBS('info', 'To download this document please reach out to support via email at operations@bizheadlaw.com')} className="btn btn-success btn-block btn-lg">
                        <i className="fas fa-download mr-2 " /> Click To Download
                    </button>
                )}
               
            </CardBody>
        </Card>
    )

}

const mapStateToProps = state => {
	return {
	    company: state.company.company,
	    bk_pct_credit_counseling: state.system.bk_pct_credit_counseling,
        selected_case: state.system.selected_case,

	};
};

export default connect(mapStateToProps, '')(Dashboard);