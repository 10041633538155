import PropTypes from 'prop-types';
import React from 'react';
import { Modal, FormGroup } from 'reactstrap';

class SignModalConfirm extends React.Component {

    render() {

        const { showModalFinish, toggleModalFinish, onSign } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModalFinish}
                toggle={toggleModalFinish}
                size="sm"
            >

                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">All Set!</h5>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleModalFinish}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                    </div>

                    <div className="modal-body">
                        <p className="text-sm mb-0">All that's left is to click the button below to adopt your signature and finish signing this document.</p>
                    </div>
                    
                    <div className="modal-body border-top">

                        <button onClick={onSign} className="btn btn-lg btn-success btn-block"><i className="fas fa-signature mr-2 " /> Sign My Document!</button>
                       
                    </div>
                    <div className="modal-body border-top">

                    <div className="text-center">
                        <i className="fas text-warning fa-arrow-up  " /> 
                        <i className="fas text-warning fa-arrow-up ml-4 " /> 
                        <i className="fas text-warning fa-arrow-up ml-4 " /> 
                        </div>

                    </div>
                </div>

            </Modal>

        );
    }
}

SignModalConfirm.propTypes = {
    showModalFinish          : PropTypes.bool.isRequired,
    toggleModalConfirm        : PropTypes.func.isRequired,
    toggleModalFinish    : PropTypes.func.isRequired,
    toggleModaonSignDisclosures    : PropTypes.func.isRequired,
}

export default SignModalConfirm
