/*
Documentation

this file handles password recovery by sending a hashed security code to a users email
it then verifies the code and lets them update their password

*/

import React from "react";
import { Link, Redirect } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { Helmet } from 'react-helmet'
import keys from 'keys';

import _companies from '_functions/companies';

import ConfirmEmail from './ConfirmEmail'
import ConfirmCode from './ConfirmCode'
import UpdatePassword from './UpdatePassword'

import { getCompanyLink } from 'utils/helpers';

class Forgot extends React.Component {

	state = {
        company: {},
        loaded: false,
        view: 'ConfirmEmail'
    };

    onConfirmedEmail = (email) => {
        this.setState({email, view: 'ConfirmCode'})
    }

    onConfirmedCode = (code) => {
        this.setState({code, view: 'UpdatePassword'})
    }

    onPasswordUpdate = () => this.setState({shouldRedirect: `/auth/login/${this.props.match.params.company_name}?reset=true`})

    componentDidMount = async () => {
        //validate the users password
		const foundCompany = await _companies.findByName(this.props.match.params.company_name);

        if(foundCompany.data) {
            this.setState({company: foundCompany.data, loaded: true})
        } else {
            this.setState({shouldRedirect: `/login`})
        }

    }


	render() {

        const { email, code, view, shouldRedirect, loaded, company } = this.state

		if(shouldRedirect) return <Redirect to={shouldRedirect} />
        if(!loaded) return <div />

		return (

		<>

            <Helmet>
                <title>Forgot Password</title>
                <meta name="description" content="Forgot Password" />
            </Helmet>

            <div className="header mb-3  pt-7 pt-lg-8 pt-lg-9">
                <Container>
                    <div className="header-body text-center mb-0">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">

                                <img style={{width: 250}} alt="..."  src={keys.LOGO_WHITE} />
                                <p className="font-weight-bold mt-3 mb-0 text-white">Forgot Your Password?</p>
                                <p className="font-weight-bold mt-0 text-white">No Problem! Enter Your Email Below</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

			<Container className="pb-5">
				<Row className="justify-content-center">
					<Col lg="5" md="7">

                        {view === 'ConfirmEmail' && (
                            <ConfirmEmail
                                company={company}
                                onConfirmedEmail={this.onConfirmedEmail}
                            />
                        )}

                        {view === 'ConfirmCode' && (
                            <ConfirmCode
                                company={company}
                                email={email}
                                onConfirmedCode={this.onConfirmedCode}
                            />
                        )}

                        {view === 'UpdatePassword' && (
                            <UpdatePassword
                                company={company}
                                code={code}
                                email={email}
                                onPasswordUpdate={this.onPasswordUpdate}
                            />
                        )}

						<Row className="mt-3">

                            <Col xs="12" className="text-center">
                                <Link to={`/auth/login/${getCompanyLink(company)}`} >
                                    <p className="text-sm font-weight-bold text-white"><i className="fas fa-arrow-left mr-2" /> Login</p>
								</Link>
							</Col>

						</Row>

					</Col>
				</Row>
			</Container>
		</>
		);
	}
}

export default Forgot

