/*
Documentation

this page holds all of our layouts and functions
that need to fire before the app runs

*/

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Auth from 'views/auth/layout';
import Ertc from 'views/ertc/layout';
import Account from 'views/account/layout';
import Cases from 'views/cases/layout';
import Actions from 'views/actions/layout';
import Errors from 'views/errors/layout';

import FailedConnection from 'views/errors/pages/errors/FailedConnection';

import SystemAlertBS from 'components/markup/system/AlertBS'
import SystemAlert from 'components/markup/system/Alert'
import SystemLoader from 'components/markup/system/Loader'
import StandardLoader from 'components/markup/system/StandardLoader'

import LoggedInRoute from 'components/system/Routing/LoggedInRoute'

import init from './init'

import Closed from './Closed'

 
class App extends Component {

    state = {
        loaded: false,
        error: false
    };

    componentDidMount = async () => {
        const loadedSuccessfully = await init();
        this.setState({loaded: true, error: !loadedSuccessfully})
    }

    componentDidCatch = (e) => {

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }


    render() {

        const date = Math.floor(new Date() / 1000);
        const Dec23 = 1671836400;
        const Jan2 = 1672668000;

        if(date >= Dec23 && date < Jan2 && !window.location.href.includes('actions')) return <Closed />

        if(this.state.shouldRedirect) {
            return window.location.href = this.state.shouldRedirect
        }

        if(!this.state.loaded) {
            return <div></div>
        }

        if(this.state.error) {
            return <FailedConnection />
        }

        return (

            <>

                <SystemAlertBS />
                <SystemAlert />
                <SystemLoader />
                <StandardLoader />

                <BrowserRouter>
                    <Switch>

                        <Route path="/cases" render={props => 
                            <LoggedInRoute>
                                <Cases {...props} /> 
                            </LoggedInRoute>
                        } />
                        <Route path="/ertc" render={props => 
                            <LoggedInRoute>
                                <Ertc {...props} /> 
                            </LoggedInRoute>
                        } />
                        <Route path="/account" render={props => 
                            <LoggedInRoute>
                                <Account {...props} /> 
                            </LoggedInRoute>
                        } />
                        <Route path="/actions" render={props => 
                            <Actions {...props} /> 
                        } />

                        <Route path="/errors" render={props => <Errors {...props} /> } />
                        <Route path="/" render={props => <Auth {...props} /> } />

                    </Switch>
                </BrowserRouter>

            </>

        )

    }

}

export default App
