import { useCallback, useState } from 'react';
import { CardHeader, CardFooter, FormFeedback, CardTitle, Badge, Row, Col, FormGroup, Input } from 'reactstrap';

const FormSection2 = ({onSetChecklist, checklist, section, setSection}) => {

    const [errs, setErrs] = useState([])

    const [rep_name, setRepName]    = useState(checklist.rep_name);
    const [rep_title, setRepTitle]  = useState(checklist.rep_title);
    const [rep_email, setRepEmail]  = useState(checklist.rep_email);
    const [rep_phone, setRepPhone]  = useState(checklist.rep_phone);


    const moveBack = useCallback(() => {
        setSection(section - 1)
        onSetChecklist({ rep_name, rep_title, rep_email, rep_phone }, section - 1)
    }, [rep_name, rep_title, rep_email, rep_phone, section, setSection, onSetChecklist])

    const moveForward = useCallback(() => {
        let _errs = [];

        if(!rep_name) _errs.push('rep_name');
        if(!rep_title) _errs.push('rep_title');
        if(!rep_email) _errs.push('rep_email');
        if(!rep_phone) _errs.push('rep_phone');

        setErrs(_errs);
        if(_errs.length) return;

        setSection(section + 1)
        onSetChecklist({ rep_name, rep_title, rep_email, rep_phone }, section + 1)
    }, [rep_name, rep_title, rep_email, rep_phone, section, setSection, onSetChecklist ])

    return (

        <>

        <CardHeader>
            <CardTitle className="mb-0"><Badge color="success" className="mr-2">Section 2</Badge> Company Representative</CardTitle>
        </CardHeader>
        
        <CardHeader>
            <p className="mb-0 border-info p-3 rounded"><i className="fas fa-info-circle mr-2 text-info" /> The individual filling out this form certifies they have the authority and ability to accurately provide the information requested herein</p>
        </CardHeader>

        <CardHeader>

            <FormGroup>
                <label className="form-control-label">Representative Name*</label>
                <Input 
                    type="text"
                    value={rep_name}
                    onChange={(e) => setRepName(e.target.value)}
                    invalid={errs.includes('rep_name') &&!rep_name}
                    valid={rep_name}
                />
                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
            </FormGroup>

            <FormGroup>
                <label className="form-control-label">Representative Title*</label>
                <Input 
                    type="text"
                    value={rep_title}
                    onChange={(e) => setRepTitle(e.target.value)}
                    invalid={errs.includes('rep_title') &&!rep_title}
                    valid={rep_title}
                />
                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
            </FormGroup>
            
            <FormGroup>
                <label className="form-control-label">Representative Email*</label>
                <Input 
                    type="text"
                    value={rep_email}
                    onChange={(e) => setRepEmail(e.target.value)}
                    invalid={errs.includes('rep_email') &&!rep_email}
                    valid={rep_email}
                />
                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
            </FormGroup>
          
            <FormGroup>
                <label className="form-control-label">Representative Phone*</label>
                <Input 
                    type="text"
                    value={rep_phone}
                    onChange={(e) => setRepPhone(e.target.value)}
                    invalid={errs.includes('rep_phone') &&!rep_phone}
                    valid={rep_phone}
                />
                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
            </FormGroup>

        </CardHeader>
    
        <CardFooter>
            <Row>
                <Col xs={6} className="align-self-center">
                    <button onClick={moveBack} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left mr-2 " /> Back
                    </button>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button onClick={moveForward} className="btn btn-success">Next</button>
                </Col>
            </Row>
        </CardFooter>

        </>

    )

}

export default FormSection2;