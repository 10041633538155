import keys from 'keys';
import React from "react";
import { Helmet } from 'react-helmet';
import { CardFooter, CardHeader, CardTitle, Card, CardBody, Col, Container, Form,  Row } from "reactstrap";

class Closed extends React.Component {


    componentDidMount = () => {
        document.body.classList.add('bg-off-black')
    }

	render() {

		return (

		<>

			<Helmet>
				<title>Closed</title>
				<meta name="description" content="Closed" />
			</Helmet>

            <div className="header  py-7 py-lg-8 pt-lg-9">
                <Container>
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">

                                <img style={{width: 250}} alt="..."  src={keys.LOGO_WHITE} />
                                <p className="font-weight-bold mt-3 text-white"><i className="fas fa-info-circle mr-2 " /> Closed For The Holidays</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

			<Container className="mt--9 pb-5">
				<Row className="justify-content-center">
					<Col lg="5" md="7">

                        <Form role="form">

                            <Card className="bg-white border-0 mb-0 z-depth-2" >

                                <CardHeader>
                                    <CardTitle className="mb-0">12/23/2022 - 1/2/2023</CardTitle>
                                </CardHeader>

                                <CardBody >
                                    <p className="text-sm mb-0">Biz Head Law is currently closed for the Holidays. Portal access will resume on January 2nd, 2023</p>
                                </CardBody>
                                
                                <CardFooter >
                                    <p className="text-sm mb-0">We wish you a wonderful holiday and look forward to assisting you in completing your ERC application in the New Year.</p>
                                </CardFooter>


                            </Card>

                        </Form>

					</Col>
				</Row>
			</Container>
		</>
		);
	}
}

export default Closed


