/*
Documentation

this page holds all the routes for the dashboard layout

*/

import Overview         from '../pages/Overview';
import Checklist        from '../pages/Checklist';
import ClientServices   from '../pages/ClientServices';
import Documents        from '../pages/Documents';
import Info             from '../pages/Info';
import FinalReport             from '../pages/FinalReport';
import Alerts           from 'components/system/Alerts';

const routes = () =>  [


    /***********************
    Dashboard
    ************************/
    {
        path: "/checklist",
        component: Checklist,
    },
    {
        path: "/client_services",
        component: ClientServices,
    },
   
    {
        path: "/documents",
        component: Documents,
    },
    {
        path: "/info",
        component: Info,
    },
    {
        path: "/final",
        component: FinalReport,
    },
   
    /***********************
    Alerts
    ************************/
    {
        path: "/alerts",
        component: Alerts,
    },

    /***********************
    DASHBOARD
    ************************/
    {
        path: "/",
        component: Overview,
    },

   
]

export default routes;
