import React from "react";
import { connect } from 'react-redux';
import {  Redirect, Route, Switch } from "react-router-dom";

import routes from "./routes";

import { setCompany } from 'store/functions/company/company'
import { load } from 'store/functions/load'

import checkLogin from 'utils/checkLogin'

import { getUrlParameter } from 'utils/urls'
import { setSelectedCase } from 'store/functions/system/system'

class Cases extends React.Component {

    state = {
        clear: getUrlParameter('clear') === 'true' ? true : false,
        loaded: false,
    };

    //get routes for this layout
    getRoutes = routes => routes().map((prop, key) => {
        return (
            <Route
                exact path={`/cases${prop.path}`}
                component={prop.component}
                key={key}
            />
        )
    })

    componentDidCatch = (e) => {
        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({redirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('focus', checkLogin)
    }

    componentDidMount = async () => {
        if(!this.props.company._id) {
            await Promise.all([
                setCompany(),
                load()
            ])
        }
        this.setState({loaded: true})
        window.addEventListener('focus', checkLogin)

        if(this.state.clear) {
            setSelectedCase({});
            this.setState({clear: false})
        }
    }

    render() {

        const { redirect, loaded, clear } = this.state
        const { company, selected_case } = this.props;

        if(redirect) return <Redirect to={redirect} />;
        if(selected_case && selected_case._id && !clear) return <Redirect to={`/${selected_case.type.toLowerCase()}`} />
        if(!loaded) return <div />;
        if(company.banned) return <Redirect to="/actions/banned" />;

        return (
            <div className="">

                <div className="wave" />

                <div ref="mainContent" onClick={this.closeSidenav} >

                    <div className="dashboard-content">
                        <Switch>
                            {this.getRoutes(routes)}
                            <Redirect from="*" to="/cases" />
                        </Switch>
                    </div>


                </div>

            </div>

        );
    }
}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    company: state.company.company,
	    selected_case: state.system.selected_case,
	};
};

export default connect(mapStateToProps, '')(Cases);

