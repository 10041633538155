import { useState } from 'react';
import { CardHeader, CardFooter, FormFeedback, CardTitle, Badge, Row, Col, FormGroup, Input } from 'reactstrap';

import PPP from './assets/PPP.png'
import ExpandableImage from 'components/functional/images/ExpandableImage';


const getWeekInputs = () => {
    let markup = [];

    for (let i = 1; i < 200; i++) {
        markup.push(<option key={i} value={i}>{i}</option>)   
    }
    return markup
}

const FormSection5 = ({onSetChecklist, checklist, section, setSection}) => {

    const [errs, setErrs] = useState([])

    const [ppp_round_1, setPPPRound1]                                 = useState(checklist.ppp_round_1);
    const [ppp_round_1_loan_amount, setPPPRound1LoanAmount]           = useState(checklist.ppp_round_1_loan_amount);
    const [ppp_round_1_loan_amount_spent, setPPPRound1AmountSpent]    = useState(checklist.ppp_round_1_loan_amount_spent);
    const [ppp_round_1_start, setPPPRound1Start]                      = useState(checklist.ppp_round_1_start);
    const [ppp_round_1_weeks, setPPPRound1Weeks]                      = useState(checklist.ppp_round_1_weeks);
    
    const [ppp_round_2, setPPPRound2]                                 = useState(checklist.ppp_round_2);
    const [ppp_round_2_loan_amount, setPPPRound2LoanAmount]           = useState(checklist.ppp_round_2_loan_amount);
    const [ppp_round_2_loan_amount_spent, setPPPRound2AmountSpent]    = useState(checklist.ppp_round_2_loan_amount_spent);
    const [ppp_round_2_start, setPPPRound2Start]                      = useState(checklist.ppp_round_2_start);
    const [ppp_round_2_weeks, setPPPRound2Weeks]                      = useState(checklist.ppp_round_2_weeks);

    const weeks = getWeekInputs();

    const setList = (_section) => {
        onSetChecklist({ 
            ppp_round_1,
            ppp_round_1_loan_amount,
            ppp_round_1_loan_amount_spent,
            ppp_round_1_start,
            ppp_round_1_weeks: parseInt(ppp_round_1_weeks),
            ppp_round_2,
            ppp_round_2_loan_amount,
            ppp_round_2_loan_amount_spent,
            ppp_round_2_start,
            ppp_round_2_weeks: parseInt(ppp_round_2_weeks),
        }, _section)
    }

    const moveBack = () => {
        setSection(section - 1);
        setList(section - 1);
    }

    const moveForward = () => {
        let _errs = [];

        if(ppp_round_1) {
            if(!ppp_round_1_loan_amount) _errs.push('ppp_round_1_loan_amount');
            if(!ppp_round_1_loan_amount_spent && ppp_round_1_loan_amount_spent !== 0) _errs.push('ppp_round_1_loan_amount_spent');
            if(!ppp_round_1_start) _errs.push('ppp_round_1_start');
            if(!ppp_round_1_weeks || ppp_round_1_weeks === 'false') _errs.push('ppp_round_1_weeks');
        }
       
        if(ppp_round_2) {
            if(!ppp_round_2_loan_amount) _errs.push('ppp_round_2_loan_amount');
            if(!ppp_round_2_loan_amount_spent && ppp_round_2_loan_amount_spent !== 0) _errs.push('ppp_round_2_loan_amount_spent');
            if(!ppp_round_2_start) _errs.push('ppp_round_2_start');
            if(!ppp_round_2_weeks || ppp_round_2_weeks === 'false') _errs.push('ppp_round_2_weeks');
        }

        setErrs(_errs);
        if(_errs.length) return;

        setSection(section + 1);
        setList(section + 1);
    }

    return (

        <>

        <CardHeader>
            <CardTitle className="mb-0"><Badge color="success" className="mr-2">Section 5</Badge> PPP Loans</CardTitle>
        </CardHeader>
        
        <CardHeader>
            <p className="mb-0 border-info p-3 rounded"><i className="fas fa-info-circle mr-2 text-info" /> All PPP loans received by your business must be properly accounted for in order to calculate any Employee Retention Credits due to your business. The information needed below can be found on Form 3508 that you were required to complete as part of your PPP forgiveness application. <br />Please check any boxes below that apply to your business</p>
        </CardHeader>

        <CardHeader>

            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={`archk_checklist_ppp_round_1`}
                                type="checkbox"
                                checked={ppp_round_1 === true}
                                onChange={() => setPPPRound1(!ppp_round_1)}
                            />
                            <label className="custom-control-label" htmlFor={`archk_checklist_ppp_round_1`}>
                                I received the FIRST round PPP loan
                            </label>
                        </div>
                    </FormGroup>

                    {ppp_round_1 ? (
                        <div className="pl-7">
                            <FormGroup>
                                <label className="form-control-label">PPP Loan Amount (See A Above)*</label>
                                <Input 
                                    type="text"
                                    value={ppp_round_1_loan_amount}
                                    onChange={(e) => setPPPRound1LoanAmount(!Number.isNaN(parseFloat(e.target.value)) ?  parseFloat(e.target.value) : '')}
                                    invalid={errs.includes('ppp_round_1_loan_amount') &&!ppp_round_1_loan_amount}
                                    valid={ppp_round_1_loan_amount}
                                />
                                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                            </FormGroup> 
                            <FormGroup>
                                <label className="form-control-label">Amount of Loan Spent on Payroll Costs (See C Above)*</label>
                                <Input 
                                    type="text"
                                    value={ppp_round_1_loan_amount_spent}
                                    onChange={(e) => setPPPRound1AmountSpent(!Number.isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : '')}
                                    invalid={errs.includes('ppp_round_1_loan_amount_spent') &&!ppp_round_1_loan_amount_spent}
                                    valid={ppp_round_1_loan_amount_spent}
                                />
                                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                            </FormGroup> 
                            <FormGroup>
                                <label className="form-control-label">Covered Period Start Date (See C Above)*</label>
                                <Input 
                                    type="date"
                                    value={ppp_round_1_start}
                                    onChange={(e) => setPPPRound1Start(e.target.value)}
                                    invalid={errs.includes('ppp_round_1_start') &&!ppp_round_1_start}
                                    valid={ppp_round_1_start}
                                />
                                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                            </FormGroup> 
                            <FormGroup>
                                <label className="form-control-label">How many weeks did you receive the PPP round 1? (See C Above)*</label>
                                <Input 
                                    type="select"
                                    value={ppp_round_1_weeks ? ppp_round_1_weeks.toString() : ppp_round_1_weeks}
                                    onChange={(e) => setPPPRound1Weeks(e.target.value)}
                                    invalid={errs.includes('ppp_round_1_weeks') &&!ppp_round_1_weeks}
                                    valid={ppp_round_1_weeks && ppp_round_1_weeks !== 'false'}
                                >
                                    <option value="false"></option>
                                    {weeks}
                                </Input>

                                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                            </FormGroup> 
                        </div>
                    ) : null}
                </Col>
                <Col lg={6}>
                    {ppp_round_1 ? (
                        <ExpandableImage>
                            <img alt="employee count" src={PPP} className="w-100 border-success rounded -depth-1 mt-3" />
                        </ExpandableImage>
                    ) : null}
                </Col>
            </Row>

        </CardHeader>

        <CardHeader>
            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={`archk_checklist_ppp_round_2`}
                                type="checkbox"
                                checked={ppp_round_2 === true}
                                onChange={() => setPPPRound2(!ppp_round_2)}
                            />
                            <label className="custom-control-label" htmlFor={`archk_checklist_ppp_round_2`}>
                                I received the SECOND round PPP loan
                            </label>
                        </div>
                    </FormGroup>

                    {ppp_round_2 ? (
                        <div className="pl-7">
                            <FormGroup>
                                <label className="form-control-label">PPP Loan Amount (See A Above)*</label>
                                <Input 
                                    type="text"
                                    value={ppp_round_2_loan_amount}
                                    onChange={(e) => setPPPRound2LoanAmount(!Number.isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : '')}
                                    invalid={errs.includes('ppp_round_2_loan_amount') &&!ppp_round_2_loan_amount}
                                    valid={ppp_round_2_loan_amount}
                                />
                                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                            </FormGroup> 
                            <FormGroup>
                                <label className="form-control-label">Amount of Loan Spent on Payroll Costs (See C Above)*</label>
                                <Input 
                                    type="text"
                                    value={ppp_round_2_loan_amount_spent}
                                    onChange={(e) => setPPPRound2AmountSpent(!Number.isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : '')}
                                    invalid={errs.includes('ppp_round_2_loan_amount_spent') &&!ppp_round_2_loan_amount_spent}
                                    valid={ppp_round_2_loan_amount_spent}
                                />
                                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                            </FormGroup> 
                            <FormGroup>
                                <label className="form-control-label">Covered Period Start Date (See C Above)*</label>
                                <Input 
                                    type="date"
                                    value={ppp_round_2_start}
                                    onChange={(e) => setPPPRound2Start(e.target.value)}
                                    invalid={errs.includes('ppp_round_2_start') &&!ppp_round_2_start}
                                    valid={ppp_round_2_start}
                                />
                                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                            </FormGroup> 

                            <FormGroup>
                                <label className="form-control-label">How many weeks did you receive the PPP round 2? (See C Above)*</label>
                                <Input 
                                    type="select"
                                    value={ppp_round_2_weeks ? ppp_round_2_weeks.toString() : ppp_round_2_weeks}
                                    onChange={(e) => setPPPRound2Weeks(e.target.value)}
                                    invalid={errs.includes('ppp_round_2_weeks') &&!ppp_round_2_weeks}
                                    valid={ppp_round_2_weeks && ppp_round_2_weeks !== 'false'}
                                >
                                    <option value="false"></option>
                                    {weeks}
                                </Input>
                                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                            </FormGroup> 
                        </div>
                    ) : null}
                </Col>

                <Col lg={6}>
                    {ppp_round_2 ? (
                        <ExpandableImage>
                            <img alt="employee count" src={PPP} className="w-100 border-success rounded -depth-1 mt-3" />
                        </ExpandableImage>
                    ) : null}
                </Col>
            </Row>
        </CardHeader>
    
        <CardFooter>
            <Row>
                <Col xs={6}>
                    <button onClick={moveBack} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left mr-2 " /> Back
                    </button>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button onClick={moveForward} className="btn btn-success">Next</button>
                </Col>
            </Row>
        </CardFooter>

        </>

    )

}

export default FormSection5;