/*
Documentation

this page holds all the routes for the dashboard layout

*/


import AccountSettings  from '../pages/Settings';


const routes = () =>  [


    /***********************
    ACCOUNT
    ************************/
    {
        path: "/settings",
        component: AccountSettings,
    },

    /***********************
    DASHBOARD
    ************************/
    {
        path: "/",
        component: AccountSettings,
    },

   
]

export default routes;
