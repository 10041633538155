/*
Documentation

this reducer holds all the actions we can dispatch for our global user object

*** make sure to declare all action types in actions.js one folder level back

*/

import * as actionTypes from '../../actions';

const initialState = {
    alerts: {
        show:     false,
        text:     null,
        type:     null,
    },
    login_redirect: '',
    loader: false,
    standardLoader: false,
    alertBS: {
        error: false,
        text: null
    },
    archk_alerts: [],
    selected_case: null,
    ertc_pct_documents: 0,
    ertc_pct_financial_info_checklist: 0,
    ertc_pct_client_services: 0,
    needs_final: null,
    zap_access: {
        access_code: '',
        identifier: '',
        err: false,
    },
    contact_alerts: []
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SELECT_CASE:

            const _case = action.payload;

            let ertc_pct_documents = 0;
            let ertc_pct_financial_info_checklist = 0;
            let ertc_pct_client_services = 0;
            let bk_pct_total = 0;
            let needs_final = null

            if(_case && _case.missing_docs) {

                

                const filterSpecialDocs = (docs) => docs.filter(doc => doc.name !== 'Completed Financial Information Checklist' && doc.name !== 'Signed Client Services Agreement' && doc.name !== 'Signed Final Report');

                let _financialInfoDoc = _case.missing_docs.find(d => d.name === 'Completed Financial Information Checklist');
                let _clientServicesDoc = _case.missing_docs.find(d => d.name === 'Signed Client Services Agreement');
                let _signed_final_report = _case.missing_docs.find(d => d.name === 'Signed Final Report');

                if(!_financialInfoDoc || _financialInfoDoc.status === 'completed') {
                    ertc_pct_financial_info_checklist = 100;
                    bk_pct_total += 33.33;
                }
                if(!_clientServicesDoc || _clientServicesDoc.status === 'completed') {
                    ertc_pct_client_services = 100;
                    bk_pct_total += 33.33;
                }

                if(_signed_final_report && _signed_final_report.status !== 'completed') {
                    needs_final = true;
                } else if(_signed_final_report) {
                    needs_final = false;
                }
    
                const filteredDocs = filterSpecialDocs(_case.missing_docs)

                let completedDocs = 0;

                filteredDocs.forEach(doc => {
                    if(doc.status === 'n/a' || doc.status === 'completed') {
                        completedDocs++;
                    }
                })

                if(completedDocs) {
                    ertc_pct_documents = Math.floor((completedDocs / filteredDocs.length) * 100)
                    bk_pct_total += ertc_pct_documents * .3333
                } 
                
                if(!filteredDocs.length) {
                    ertc_pct_documents = 100
                    bk_pct_total += ertc_pct_documents * .3333
                }
            }

            if(bk_pct_total > 99.5) bk_pct_total = 100;

            bk_pct_total = Math.floor(bk_pct_total)

            if(needs_final) {
                bk_pct_total = 95
            }

            return {
                ...state,
                selected_case: _case,
                ertc_pct_documents,
                ertc_pct_financial_info_checklist,
                ertc_pct_client_services,
                needs_final,
                bk_pct_total,
                zap_access: {
                    access_code: '',
                    identifier: '',
                    err: false,
                },
                contact_alerts: []
            }
            
        case actionTypes.SET_ZAP_ACCESS:

            return {
                ...state,
                zap_access: {
                    access_code: action.payload.access_code,
                    identifier: action.payload.identifier,
                    err: action.payload.err,
                }
            }
      
        case actionTypes.SET_CONTACT_ALERTS:

            return {
                ...state,
                contact_alerts: action.payload
            }
      
        case actionTypes.TOGGLE_SYSTEM_ALERT:

            return {
                ...state,
                alerts: {
                    show: action.payload.boolean,
                    text: action.payload.text,
                    type: action.payload.type,
                }
            }

        case actionTypes.TOGGLE_SYSTEM_LOADER:

            return {
                ...state,
                loader: action.payload,
            }

        case actionTypes.SET_LOGIN_REDIRECT:

            return {
                ...state,
                login_redirect: action.payload,
            }

        case actionTypes.TOGGLE_STANDARD_LOADER:

            return {
                ...state,
                standardLoader: action.payload,
            }
        
        case actionTypes.SET_ARCHK_ALERTS:

            return {
                ...state,
                archk_alerts: action.payload,
            }

        case actionTypes.TOGGLE_SYSTEM_ALERT_BS:

            if(action.payload && action.payload.error !== undefined && action.payload.text) {

                return {
                    ...state,
                    alertBS: {
                        error: action.payload.error,
                        text: action.payload.text,
                    }
                }

            } else {

                return {
                    ...state,
                    alertBS: null
                }

            }

        default:

            return state;

    }

}

export default reducer;
