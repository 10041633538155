import React from 'react';
import { Modal } from 'reactstrap';

class SignModalConfirm extends React.Component {

    render() {

        const { showModalConfirm, toggleModalDisclosures, toggleModalConfirm } = this.props

        return (

            <Modal
                className="modal-dialog-centered"
                isOpen={showModalConfirm}
                toggle={() => {}}
                size="md"
            >

                <div>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Let's Get Started!</h5>
                    </div>

                    <div className="modal-body">

                        <p className="text-sm mb-0">
                            I consent to executing this agreement electronically in accordance with{' '}
                            <span onClick={toggleModalDisclosures} className="cursor-pointer text-underline text-dark font-weight-bold">UETA Consent Disclosures</span>
                        </p>

                    </div>

                    <div className="modal-footer">
                        <button onClick={toggleModalConfirm} className="btn btn-outline-success">I Agree</button>
                    </div>
                </div>

            </Modal>

        );
    }
}

export default SignModalConfirm
