import { connect } from 'react-redux';
import { CardHeader, Card, CardTitle, CardBody } from "reactstrap";

import { Link } from 'react-router-dom';

import CreditCounselingImage from '../assets/CreditCounselingImage.svg'
import ConfirmedImage from 'assets/img/misc/ConfirmedImage.svg'

import cardStyles from '../../_styles/cards'

import onDownloadDocument from '_functions/documents/download';

const Dashboard = ({ertc_pct_client_services, selected_case}) => {

    const finished = ertc_pct_client_services === 100;
    const agreement = selected_case.missing_docs.find(d => d.name === 'Signed Client Services Agreement')
    console.log(agreement)
    
    return (
        <Card style={cardStyles} className={finished ? 'main-card border-success' : 'main-card border'}>
            
            <CardHeader>
                <CardTitle className="mb-0">Client Services <span className="float-right">({ertc_pct_client_services}%)</span> </CardTitle>
            </CardHeader>
            
            <CardHeader className="bg-secondary image-container" style={{maxHeight: 142}}>
                <div className="text-center">
                    <img src={finished ? ConfirmedImage : CreditCounselingImage} alt="..." className={`w-50 ${finished ? 'ml-7': ''}`} />
                </div>
            </CardHeader>

            <CardHeader>
                {finished ? (
                    <p className="text-sm mb-0">You're all set here! Your Client Services Agreement has been signed and all parties are finalized. There is no further action you need to take in this area of your case.</p>
                    ) : (
                    <p className="text-sm mb-0">The Client Services Agreement must be signed before we can begin working on your tax credit. To get started and sign the agreement check your email for the link that was sent to you.</p>
                )}
            </CardHeader>
            
                {finished ? (
                    <CardBody>
                        <button onClick={() => onDownloadDocument(agreement.documents[0])} className="btn btn-success btn-block btn-lg"><i className="fas fa-download mr-2"  /> Download</button>
                    </CardBody>

                ) : (
                    <CardBody>
                        <button disabled={true} className="btn btn-warning btn-block btn-lg"><i className="fas fa-envelope mr-2"  /> Check Your Email</button>
                    </CardBody>
                )}
        </Card>
    )

}

const mapStateToProps = state => {
	return {
	    company: state.company.company,
	    ertc_pct_client_services: state.system.ertc_pct_client_services,
	    selected_case: state.system.selected_case,
	};
};

export default connect(mapStateToProps, '')(Dashboard);