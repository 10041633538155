import { useState } from 'react';
import { CardHeader, CardFooter, FormFeedback, CardTitle, Badge, Row, Col, FormGroup, Input } from 'reactstrap';

import SocialSecurity from './assets/SocialSecurity.png'
import ExpandableImage from 'components/functional/images/ExpandableImage';

const FormSection2 = ({onSetChecklist, checklist, section, setSection}) => {

    const [errs, setErrs] = useState([])

    const [social_security_wages_quarter_4_2020, setQ42020]           = useState(checklist.social_security_wages_quarter_4_2020);
    const [social_security_wages_quarter_1_2021, setQ12021]           = useState(checklist.social_security_wages_quarter_1_2021);
    const [social_security_wages_quarter_2_2021, setQ22021]           = useState(checklist.social_security_wages_quarter_2_2021);
    const [social_security_wages_quarter_3_2021, setQ32021]           = useState(checklist.social_security_wages_quarter_3_2021);

    const setList = (_section) => {
        onSetChecklist({
            social_security_wages_quarter_4_2020,
            social_security_wages_quarter_1_2021,
            social_security_wages_quarter_2_2021,
            social_security_wages_quarter_3_2021,
        }, _section)
    }

    const moveBack =() => {
        setSection(section - 1)
        setList(section - 1);
    }

    const moveForward =() => {
        let _errs = [];

        if(!social_security_wages_quarter_4_2020) _errs.push('social_security_wages_quarter_4_2020');
        if(!social_security_wages_quarter_1_2021) _errs.push('social_security_wages_quarter_1_2021');
        if(!social_security_wages_quarter_2_2021) _errs.push('social_security_wages_quarter_2_2021');
        if(!social_security_wages_quarter_3_2021) _errs.push('social_security_wages_quarter_3_2021');

        setErrs(_errs);
        if(_errs.length) return;

        setSection(section + 1)
        setList(section + 1);
    }

    return (

        <>

        <CardHeader>
            <CardTitle className="mb-0"><Badge color="success" className="mr-2">Section 7</Badge> Taxable Social Security Wages</CardTitle>
        </CardHeader>
        
        <CardHeader>
            <p className="mb-0 border-info p-3 rounded"><i className="fas fa-info-circle mr-2 text-info" /> Please enter the taxable social security wages for each of the quarters below. These numbers can be found on Line 5A Column 1 of your 941 Filings.</p>


        </CardHeader>
        
        <CardHeader>

            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <label className="form-control-label">2020 - Q4*</label>
                        <p className="text-sm mb-0 font-weight-bold">FIELD 5a OF YOUR <b className="text-underline">Q4 2020</b> Form 941</p>
                        <Input 
                            type="number"
                            value={social_security_wages_quarter_4_2020}
                            onChange={(e) => setQ42020(parseFloat(e.target.value))}
                            invalid={errs.includes('social_security_wages_quarter_4_2020') &&!social_security_wages_quarter_4_2020}
                            valid={social_security_wages_quarter_4_2020}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                
                    <FormGroup>
                        <label className="form-control-label">2021 - Q1*</label>
                        <p className="text-sm mb-0 font-weight-bold">FIELD 5a OF YOUR <b className="text-underline">Q1 2021</b> Form 941</p>
                        <Input 
                            type="number"
                            value={social_security_wages_quarter_1_2021}
                            onChange={(e) => setQ12021(parseFloat(e.target.value))}
                            invalid={errs.includes('social_security_wages_quarter_1_2021') &&!social_security_wages_quarter_1_2021}
                            valid={social_security_wages_quarter_1_2021}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                
                    <FormGroup>
                        <label className="form-control-label">2021 - Q2*</label>
                        <p className="text-sm mb-0 font-weight-bold">FIELD 5a OF YOUR <b className="text-underline">Q2 2021</b> Form 941</p>
                        <Input 
                            type="number"
                            value={social_security_wages_quarter_2_2021}
                            onChange={(e) => setQ22021(parseFloat(e.target.value))}
                            invalid={errs.includes('social_security_wages_quarter_2_2021') &&!social_security_wages_quarter_2_2021}
                            valid={social_security_wages_quarter_2_2021}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                
                    <FormGroup>
                        <label className="form-control-label">2021 - Q3*</label>
                        <p className="text-sm mb-0 font-weight-bold">FIELD 5a OF YOUR <b className="text-underline">Q3 2021</b> Form 941</p>
                        <Input 
                            type="number"
                            value={social_security_wages_quarter_3_2021}
                            onChange={(e) => setQ32021(parseFloat(e.target.value))}
                            invalid={errs.includes('social_security_wages_quarter_3_2021') &&!social_security_wages_quarter_3_2021}
                            valid={social_security_wages_quarter_3_2021}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
          
                </Col>
                <Col lg={6}>
                    <ExpandableImage>
                        <img alt="employee count" src={SocialSecurity} className="w-100 border-success rounded mt-3" />
                    </ExpandableImage>
                </Col>
            </Row>

        </CardHeader>
    
        <CardFooter>
            <Row>
                <Col xs={6} className="align-self-center">
                    <button onClick={moveBack} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left mr-2 " /> Back
                    </button>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button onClick={moveForward} className="btn btn-success">Next</button>
                </Col>
            </Row>
        </CardFooter>

        </>

    )

}

export default FormSection2;