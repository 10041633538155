/*
Documentation

This page handles updating a users profile

*/


import React from "react";

import { connect } from 'react-redux';
import { CardFooter, Card, CardBody, CardHeader, Form, FormGroup, Input } from "reactstrap";
import { toggleAlertBS, toggleStandardLoader } from 'store/functions/system/system';

import _auth from   '_functions/auth';


class AccountPassword extends React.Component {

    state = {
        error: false,
        password: null,
        passwordConfirm: null,
        canSave: true
    }

    onPasswordUpdate = async  (e) => {

        e.preventDefault();

        this.setState({error: false})

        const { password, passwordConfirm } = this.state;
        if(!password || !passwordConfirm) return;

        toggleStandardLoader(true)

        const updated = await _auth.password.update({
            user_id: this.props.viewing_user._id,
            password,
            passwordConfirm
        })

        toggleStandardLoader(false)

        if(updated.success) {

            toggleAlertBS(false, 'Your password has been successfully changed')
            this.setState({ password: null, passwordConfirm: null })

        } else {

            this.setState({error: updated.message})

        }

    }

    render() {

        const { error, password, passwordConfirm } = this.state;

        return (

            <Form>

                <Card className="mt-4">

                    <CardHeader>
                        <h3 className="mb-0">Security</h3>
                    </CardHeader>

                    <CardBody>

                        <h6 className="heading-small text-muted mb-4">Change Password</h6>

                        <FormGroup>
                            <label className="form-control-label" htmlFor="password" >New Password</label>
                            <Input
                                autoComplete="new-password"
                                name="new-password"
                                id="password"
                                value={password || ''}
                                type="password"
                                onChange={e => this.setState({password: e.target.value}) }
                            />
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label" htmlFor="passwordConfirm" >Confirm New Password</label>
                            <Input
                                autoComplete="new-password"
                                name="new-password"
                                id="passwordConfirm"
                                value={passwordConfirm || ''}
                                type="password"
                                onChange={e => this.setState({passwordConfirm: e.target.value}) }
                            />
                        </FormGroup>


                    </CardBody>

                    <CardFooter>
                        <p className="text-sm mb-0">When changing your password make sure to not reuse any that you have in the past and ensure that it is not easily guessed by others trying to access your account.</p>
                    </CardFooter>

                    {error && (
                        <CardFooter>
                            <p className="text-sm font-weight-bold text-danger mb-0">{error}</p>
                        </CardFooter>
                    )}

                    <CardFooter className="text-right">
                        <button className="btn btn-success" onClick={this.onPasswordUpdate} disabled={this.state.canSave ? false : true} >
                            Update Password
                        </button>
                    </CardFooter>


                </Card>

            </Form>
        );
    }
}

const mapStateToProps = state => {
    return {
        viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(AccountPassword);
