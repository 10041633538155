
import apiWorker from '../apiWorker'

const contacts = {    
    find : () =>  apiWorker('get',  `/v1/api/cases`, null, true),
    findById : (case_id) =>  apiWorker('get',  `/v1/api/cases/${case_id}`, null, true),
    saveChecklist : (data) =>  apiWorker('post',  `/v1/api/cases/checklist`, data, true),
    setLoggedIn : (data) =>  apiWorker('post',  `/v1/api/cases/setLoggedIn`, data, true),
}

export default contacts;