import { useCallback } from "react";

import ModalUpload from './ModalUpload'


import ModalToggler from 'components/functional/modals/Toggler'
import { toggleAlertBS, toggleStandardLoader } from "store/functions/system/system";
import useEnhancedReducer from 'utils/useEnhancedReducer'

import Dots from 'components/markup/loading/Dots';

const Upload = ({fetchCase, index, missingDoc, missingDocuments, foundCase, status}) => {

    const reducer = useCallback((state, action) => {
        if(action.increment) return { uploading: state.uploading + 1 } 
        return { uploading: state.uploading - 1 }
    }, [])

    const [state, dispatch] = useEnhancedReducer(reducer, { uploading: 0 });

    const onUploadStarted = useCallback(() => {
        toggleStandardLoader(true)
        dispatch({increment: true})
    }, [dispatch]);

    const onUpload = useCallback(() => {
        dispatch({decrement: true})
        toggleStandardLoader(false)
        fetchCase()
        toggleAlertBS(false, 'Document Uploaded Successfully.')

    }, [dispatch, fetchCase])
    
    const onError = useCallback(() => {
        dispatch({decrement: true})
        toggleStandardLoader(false)
        toggleAlertBS('info', 'An issue occurred uploading a document, please try again.')
    }, [dispatch])

    const canLinkZap = missingDoc.name === 'Paystubs' || missingDoc.name === 'Pay Stubs' || missingDoc.name === 'Bank Statements';

    return (

        <div className="bankruptcy-upload-doc-card">

            <div className="text-right">
                <ModalToggler 
                    component={ModalUpload} 
                    missingDoc={missingDoc} 
                    foundCase={foundCase} 
                    onUpload={onUpload} 
                    onUploadStarted={onUploadStarted}
                    onError={onError}
                    fetchCase={fetchCase}
                >
                    <button style={{minWidth: 0 }} className={`btn btn-success btn-sm py-1 px-4 ${canLinkZap ? 'btn-info' : 'btn-success btn-rounded'}`}>
                        <i className="fas fa-upload mr-2 " /> Upload
                    </button>
                </ModalToggler>
            </div>

            <div className={`wrapper rounded bg-secondary border-info ${index === missingDocuments.length -1 ? '' : 'mb-4'}`} style={{borderSize: '3px'}}>
                <div className="p-4 border-bottom text-dark">
                    {status === 'pending' ? (
                        <i className="fas fa-info text-warning mr-2" />
                    ) : (
                        <i className="far fa-folder-open mr-2" />
                    )} 
                    {missingDoc.name}
                </div>
                {status === 'pending' ? (
                     <div className="px-4 py-2 border-bottom text-dark">
                     <small className="text-warning">PENDING</small> 
                 </div>
                ) : ''} 
               

                {state.uploading > 0 ? (
                    <div className="px-4 py-2 border-bottom bg-secondar">
                        <p className="text-sm mb-0">Uploading {state.uploading} document{state.uploading > 1 ? 's' : ''} <Dots /></p>
                    </div>
                ) : null}

                {missingDoc.description ? (
                    <div className="px-4 py-2 border-bottom bg-secondar">
                        <p className="text-sm mb-0">{missingDoc.description}</p>
                    </div>
                ) : null}
                <div>

                {missingDoc.documents.length ? (
                    <div className="p-4">
                        {missingDoc.documents.map(d => (
                            <div className="p-3 rounded border mb-3 bg-white">{d.friendly_name}</div>
                        ))}
                    </div>
                ) : (
                    <div className="p-4">
                        <p className="text-sm mb-0">No Documents Uploaded</p>
                    </div>
                )}
                </div>
            </div>

        </div>
    )
}

export default Upload;

