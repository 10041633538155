import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Card, Container, CardTitle, CardHeader, CardFooter } from "reactstrap";
import moment from 'moment'

import reactHTMLParser from 'react-html-parser'

const ContactAlerts = ({contact_alerts}) => (
    <Container fluid>

        <Helmet>
            <title>My Alerts</title>
            <meta name="description" content="My Alerts" />
        </Helmet>

        <Card>

            <CardHeader>
                <CardTitle className="mb-0">My Alerts</CardTitle>
            </CardHeader>

            <div className="table-responsive">
                <table className="table mb-0">

                    <thead>
                        <tr>
                            <th>Date</th>
                            <th className="text-right">Resolved</th>
                        </tr>
                    </thead>

                    <tbody>
                    {contact_alerts && contact_alerts.length ? contact_alerts.map(alert => (
                        <tr key={alert._id}>
                            <td >
                                <small>{moment.unix(alert.created_at).format('MM/DD/YY - h:mm A')}</small>
                                <div>{reactHTMLParser(alert.value)}</div>
                            </td>
                            <td className="text-right" style={{width: 50, minWidth: 50}}>
                                {alert.resolved ?
                                    <span><i className="fas fa-check text-success" /></span> :
                                    <i className="fas fa-times text-danger" />}
                            </td>
                        </tr>
                    )) : (
                        <tr>
                            <td><i className="fas fa-check text-success mr-2" /> You have no alerts on your account.</td>
                            <td></td>
                        </tr>
                    )}
                    </tbody>

                </table>
            </div>

            <CardFooter>
                <p className="sm mb-0"><small><small>*** For questions on any notification please reach out to your designated representative.</small></small></p>
            </CardFooter>

        </Card>

    </Container>
)

const mapStateToProps = state => {
    return {
        contact_alerts: state.system.contact_alerts,
    };
};

export default connect(mapStateToProps, '')(ContactAlerts);