import { Col, Container, Row, Progress } from "reactstrap";
import { connect } from 'react-redux';

const NavbarDesktop = ({selected_case, bk_pct_total}) => {

    const finished = bk_pct_total === 100;

    return (

        <Container id="desktop-navbar-top" fluid>

            <div className="header">

                <div className="header-body">
                    <Row className="py-3">

                        <Col>
                            <div className="progress-wrapper pt-0">
                                <Row className="mb-3">
                                    <Col xs={6}>
                                        <div className="progress-primary">
                                            <div className="progress-label">
                                                <span>Case Progress - {selected_case.type}</span>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={6}>
                                        <div className="progress-primary text-right">
                                            <div className="progress-percentage">
                                                <span>{bk_pct_total}%</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Progress max="100" value={bk_pct_total} color={finished ? 'success' : 'primary'} />
                            </div>
                        </Col>

                        {/* <div className="col-auto text-right" style={{width: 220}}>

                            <ul className="navbar-icons">

                                <li>
                                    <Link to="/dashboard/notifications/all" className="circle-wrapper z-depth-2">
                                        <i className="fas fa-comments " />
                                        <span className="circle-wrapper-marker z-depth-1">{notifications > 99 ? '99+' : notifications}</span>
                                    </Link>
                                </li>
                               
                            </ul>

                            <Nav className="align-items-center ml-auto ml-md-0 pl-5 "  navbar>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle style={{position: 'relative', top: -7}} className="nav-link pr-0 cursor-pointer font-weight-bold" color="" tag="a">
                                        My Account <i className="fas fa-caret-down ml-2 " />
                                    </DropdownToggle>

                                    <DropdownMenu right>
                                        <div className="dropdown-content">
                                            <DropdownItem  className="noti-title" header tag="div">
                                                <h6 className="text-overflow m-0">My Account</h6>
                                            </DropdownItem>

                                            <DropdownItem tag={Link} to="/dashboard/settings" >
                                                <i className="ni ni-user-run" />
                                                <span>Settings</span>
                                            </DropdownItem>
                                            <DropdownItem tag={Link} to="/auth/logout" >
                                                <i className="ni ni-user-run" />
                                                <span>Logout</span>
                                            </DropdownItem>
                                        </div>
                                    </DropdownMenu>

                                </UncontrolledDropdown>
                            </Nav>

                        </div> */}


                    </Row>
                </div>

            </div>

        </Container>

    )

}


const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    selected_case: state.system.selected_case,
        bk_pct_total: state.system.bk_pct_total
	};
};

export default connect(mapStateToProps, '')(NavbarDesktop);
