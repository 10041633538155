/*
Documentation

this is the wrapping component for all auth routes
if a user is already known and logged in it will redirect
them off to their destination

if not destination is know we will redirect to /dashboard

*/

import React, { Component } from "react";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import routes from "../layout/routes";
import { getUrlParameter } from 'utils/urls';

class Auth extends Component {

    state = {}

    componentDidMount() {

        //if we are not on the logout page
        if(window.location.pathname !== '/logout') {
            const redirect = getUrlParameter('redirect')

            const viewing_user = this.props.viewing_user;

            if(viewing_user && viewing_user.logged_in) {

                if(redirect) {

                    this.setState({shouldRedirect: redirect})

                } else {

                    this.setState({shouldRedirect: '/cases'})

                }

            }
        }

    }

    componentWillUnmount() {
        document.body.classList.remove('bg-off-black')
    }

    componentDidUpdate(e) {

        if (e.history.pathname !== e.location.pathname) {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;

            if(this.refs.mainContent) {
                this.refs.mainContent.scrollTop = 0;
            }
        }

    }
    getRoutes = routes => {
        return routes.map((prop, key) => {
            return (
                <Route
                    path={`${prop.path}`}
                    component={prop.component}
                    key={key}
                />
            );

        });
    };

    getRedirectUrl = () => {
        var getUrl = window.location;
        var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];

        return baseUrl
    }

    componentDidCatch = (e) => {

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    componentDidMount = () => {
        document.body.classList.add('bg-off-black')
        const { viewing_user } = this.props;
        if(viewing_user && viewing_user.logged_in) {
            if(window.location.pathname !== '/logout') {
                this.setState({shouldRedirect: '/cases'})
            }
        }
    }

    render() {

        if(this.state.shouldRedirect) {
            return <Redirect to={this.state.shouldRedirect} />
        }

        return (

        <>
            <div className="layout-auth" ref="mainContent">
                <Switch>{this.getRoutes(routes)}</Switch>
            </div>
        </>
        );
    }
}

const mapStateToProps = state => {
    return {
      viewing_user: state.auth.viewing_user,
    };
};

export default connect(mapStateToProps, '')(Auth);

