import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Container, Row, Col, Card, CardBody } from "reactstrap";

import ChecklistCard from '../Checklist/Card'
import ClientServicesCard from '../ClientServices/Card'
import DocumentsCard from '../Documents/Card'
import FinalReportCard from '../FinalReport/Card'
// import InfoCard from '../Info/Card'

import CompletedImage from './assets/CompletedImage.svg'

const Dashboard = ({ertc_pct_documents, ertc_pct_financial_info_checklist, ertc_pct_client_services, company, selected_case, needs_final}) => {

    const completedDocuments                = ertc_pct_documents === 100;
    const completedFinancialInfoChecklist   = ertc_pct_financial_info_checklist === 100;
    const completedClientServices           = ertc_pct_client_services === 100;

    const hasPending = selected_case.missing_docs.some(d => d.status === 'pending');

    return (
        <Container fluid>

            <Helmet>
                <title>{`Contacts`}</title>
                <meta name="description" content="Contacts" />
            </Helmet>

            {hasPending ? (
                <div className="alert alert-success"><i className="fas fa-info-circle mr-2 " /> One or more of your uploaded documents are currently under review by our staff.</div>
            ) : null}

            {!completedDocuments || !completedFinancialInfoChecklist || !completedClientServices || needs_final ? (
                <div>
                    <h2>Needs Attention</h2>
                    <Row className="row-sm-auto"> 
                        {!completedDocuments ? (
                            <div className="col-auto mb-4" >
                                <DocumentsCard />
                            </div>
                        ) : null}
                        
                        {!completedFinancialInfoChecklist ? (
                            <div className="col-auto mb-4" >
                                <ChecklistCard />
                            </div>
                        ) : null}
                        
                        {!completedClientServices ? (
                            <div className="col-auto mb-4">
                                <ClientServicesCard />
                            </div>
                        ) : null}
                        {needs_final ? (
                            <div className="col-auto mb-4">
                                <FinalReportCard />
                            </div>
                        ) : null}
                    </Row>
                </div>
            ) : (
                <Card className="mb-4 main-card border-success">
                    <CardBody className="p-0">
                        <Row>
                            <Col className="align-self-center border-right">
                                <div className="rounded text-center" style={{overflow: 'hidden'}}>
                                    <img src={CompletedImage} alt="..." className="w-50" />
                                </div>
                            </Col>
                            <div className="col-auto align-self-center" style={{width: 'calc(100% - 150px)'}}>
                                <div className="p-4">
                                    <p className="mb-0"><i className="fas fa-check-double mr-2 text-info " /> That's it! You're all set with finishing your checklist, client services agreement, and uploading your documents. <b className="text-dark font-weight-bold text-underline">{company.name}</b> should be in touch with you shortly.</p>
                                </div>
                            </div>
                        </Row>
                    </CardBody>
                </Card>

            )}

            {completedDocuments || completedFinancialInfoChecklist || completedClientServices ? (
                <div>
                    <h2>Completed Tasks</h2>
                    <Row className="row-sm-auto">
                        {completedDocuments ? (
                            <div className="col-auto mb-4" >
                                <DocumentsCard />
                            </div>
                        ) : null}
                        
                        {completedFinancialInfoChecklist ? (
                            <div className="col-auto mb-4" >
                                <ChecklistCard />
                            </div>
                        ) : null}
                        
                        {completedClientServices ? (
                            <div className="col-auto mb-4">
                                <ClientServicesCard />
                            </div>
                        ) : null}
                    </Row>
                </div>
            ) : null}

            {/* <h2>Information</h2>
            <Row>
                <div className="col-auto mb-4">
                    <InfoCard />
                </div>
            </Row> */}

           
        </Container>
    )

}


const mapStateToProps = state => {
	return {
	    company: state.company.company,
        selected_case: state.system.selected_case,
        ertc_pct_documents: state.system.ertc_pct_documents,
        ertc_pct_financial_info_checklist: state.system.ertc_pct_financial_info_checklist,
        ertc_pct_client_services: state.system.ertc_pct_client_services,
        needs_final: state.system.needs_final,
	};
};

export default connect(mapStateToProps, '')(Dashboard);