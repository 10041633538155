import { useCallback, useState } from 'react';
import { CardHeader, CardFooter, FormFeedback, CardTitle, Badge, Row, Col, FormGroup, Input } from 'reactstrap';

import EmployeeCount from './assets/EmployeeCount.png'

import ExpandableImage from 'components/functional/images/ExpandableImage';
import ConfirmationModal from 'components/functional/modals/Confirmation.js';

const FormSection3 = ({onSetChecklist, checklist, section, setSection}) => {

    const [errs, setErrs] = useState([])
    const [showModal, setShowModal] = useState(false)

    const [employees_quarter_4_2020, setQuarter42020]           = useState(checklist.employees_quarter_4_2020);
    const [employees_quarter_1_2021, setQuarter12021]           = useState(checklist.employees_quarter_1_2021);
    const [employees_quarter_2_2021, setQuarter22021]           = useState(checklist.employees_quarter_2_2021);
    const [employees_quarter_3_2021, setQuarter32021]           = useState(checklist.employees_quarter_3_2021);


    const moveBack = useCallback(() => {
        setSection(section - 1)
        onSetChecklist({ employees_quarter_4_2020, employees_quarter_1_2021, employees_quarter_2_2021, employees_quarter_3_2021 }, section - 1)
    }, [employees_quarter_4_2020, employees_quarter_1_2021, employees_quarter_2_2021, employees_quarter_3_2021, section, setSection, onSetChecklist])

    const moveForward = useCallback((force) => {
        let _errs = [];

        if(!employees_quarter_4_2020 && employees_quarter_4_2020 !== 0) _errs.push('employees_quarter_4_2020');
        if(!employees_quarter_1_2021 && employees_quarter_1_2021 !== 0) _errs.push('employees_quarter_1_2021');
        if(!employees_quarter_2_2021 && employees_quarter_2_2021 !== 0) _errs.push('employees_quarter_2_2021');
        if(!employees_quarter_3_2021 && employees_quarter_3_2021 !== 0) _errs.push('employees_quarter_3_2021');

        if(employees_quarter_4_2020 === 0 || employees_quarter_1_2021 === 0 || employees_quarter_2_2021 === 0 || employees_quarter_3_2021 === 0) {
            if(!force) return setShowModal(true)
        }

        setErrs(_errs);
        if(_errs.length) return;

        setSection(section + 1)
        onSetChecklist({ employees_quarter_4_2020, employees_quarter_1_2021, employees_quarter_2_2021, employees_quarter_3_2021 }, section + 1)
    }, [employees_quarter_4_2020, employees_quarter_1_2021, employees_quarter_2_2021, employees_quarter_3_2021, section, setSection, onSetChecklist ])

    return (

        <>

        <ConfirmationModal 
            showModal={showModal}
            toggleModal={() => setShowModal(false)}
            onConfirmation={async () => {
                moveForward(true)
            }} 
            title="Confirm Employee Count" 
            body={(
                <span>One or more counts for employees during a quarter is set to 0. Are you sure you wish to move forward?</span>
            )}
        /> 

        <CardHeader>
            <CardTitle className="mb-0"><Badge color="success" className="mr-2">Section 3</Badge> Employee Count</CardTitle>
        </CardHeader>
        
        <CardHeader>
            <p className="mb-0 border-info p-3 rounded mb-3"><i className="fas fa-info-circle mr-2 text-info" /> Please look at your 941 filings for each of the applicable quarters and enter the number of employees from line 1. (See Screenshot Below)</p>
        </CardHeader>

        <CardHeader>
            <CardTitle className="mb-0">Enter Form Line 1 Info Below</CardTitle>
        </CardHeader>

        <CardHeader>

            <Row>
                <Col lg={6} className="">
                    <FormGroup>
                        <label className="form-control-label">Employee Count - Quarter 4 2020*</label>
                        <Input 
                            type="number"
                            value={employees_quarter_4_2020}
                            onChange={(e) => setQuarter42020(parseInt(e.target.value))}
                            invalid={errs.includes('employees_quarter_4_2020') &&!employees_quarter_4_2020}
                            valid={employees_quarter_4_2020}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label">Employee Count - Quarter 1 2021*</label>
                        <Input 
                            type="number"
                            value={employees_quarter_1_2021}
                            onChange={(e) => setQuarter12021(parseInt(e.target.value))}
                            invalid={errs.includes('employees_quarter_1_2021') &&!employees_quarter_1_2021}
                            valid={employees_quarter_1_2021}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                    
                    <FormGroup>
                        <label className="form-control-label">Employee Count - Quarter 2 2021*</label>
                        <Input 
                            type="number"
                            value={employees_quarter_2_2021}
                            onChange={(e) => setQuarter22021(parseInt(e.target.value))}
                            invalid={errs.includes('employees_quarter_2_2021') &&!employees_quarter_2_2021}
                            valid={employees_quarter_2_2021}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                
                    <FormGroup>
                        <label className="form-control-label">Employee Count - Quarter 3 2021*</label>
                        <Input 
                            type="number"
                            value={employees_quarter_3_2021}
                            onChange={(e) => setQuarter32021(parseInt(e.target.value))}
                            invalid={errs.includes('employees_quarter_3_2021') &&!employees_quarter_3_2021}
                            valid={employees_quarter_3_2021}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                </Col>
                <Col lg={6} className="">
                    <ExpandableImage>
                        <img 
                            alt="employee count" 
                            src={EmployeeCount} 
                            className="w-100 border-success rounded -depth-1" 
                        />
                    </ExpandableImage>
                    
                </Col>
            </Row>

        </CardHeader>
    
        <CardFooter>
            <Row>
                <Col xs={6} className="align-self-center">
                    <button onClick={moveBack} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left mr-2 " /> Back
                    </button>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button onClick={() => moveForward()} className="btn btn-success">Next</button>
                </Col>
            </Row>
        </CardFooter>

        </>

    )

}

export default FormSection3;