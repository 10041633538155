import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Container, Row } from "reactstrap";

import FinalReportGetStarted from './GetStarted'
import FinalReportUpload from './Upload'
import FinalReportCard from './Card'

const Dashboard = ({needs_final}) => (
    <Container fluid>

        <Helmet>
            <title>{`Questionnaire`}</title>
            <meta name="description" content="Questionnaire" />
        </Helmet>

        {!needs_final ? (
            <FinalReportCard />
        ) : (
            <Row className="row-sm-auto">
                <div className="col-auto">
                    <FinalReportGetStarted />
                </div>
                <div className="col-auto">
                    <div className="text-center d-none d-md-block" style={{paddingTop: 175}}>
                        <i className="fas fa-arrow-right text-warning" /> 
                    </div>
                </div>
                <div className="col-auto">
                    <FinalReportUpload />
                </div>
            </Row>
        )}

    </Container>
)

const mapStateToProps = state => {
	return {
        needs_final: state.system.needs_final,
	};
};

export default connect(mapStateToProps, '')(Dashboard);