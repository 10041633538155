
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Container } from "reactstrap";

import ChecklistCard from './Card'
import ChecklistForm from './Form'

const Checklist = ({ertc_pct_financial_info_checklist}) => {

    const completedFinancialInfoChecklist    = ertc_pct_financial_info_checklist === 100;
    
    return (
        <Container fluid>
    
            <Helmet>
                <title>{`Checklist`}</title>
                <meta name="description" content="Checklist" />
            </Helmet>
    
            {completedFinancialInfoChecklist ? (
                <ChecklistCard />
            ) : (
                <ChecklistForm />
            )}
                
        </Container>
    )

}

const mapStateToProps = state => {
	return {
	    company: state.company.company,
        ertc_pct_documents: state.system.ertc_pct_documents,
        ertc_pct_financial_info_checklist: state.system.ertc_pct_financial_info_checklist,
        ertc_pct_client_services: state.system.ertc_pct_client_services,
	};
};

export default connect(mapStateToProps, '')(Checklist);