
import * as actionTypes from '../../actions';
import store from '../../index';

import _load from '_functions/load';

import { setSelectedCase } from '../system/system'

export const load = async() => {
    return new Promise( async resolve => {

        const loaded = await _load.standard();

        if(loaded.success) {

            // if we only have one open case mark it as selected
            if(loaded.data.cases) {
                if(loaded.data.cases.length === 1) setSelectedCase(loaded.data.cases[0]);
            }

            store.dispatch({ type: actionTypes.SET_LOADED, payload: loaded.data });
            resolve({success: true});

        } else {

            resolve({success: false});

        }

    })

}
