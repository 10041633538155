import React from "react";
import { connect } from 'react-redux';
import {  Redirect, Route, Switch } from "react-router-dom";

import Footer from "components/system/Footer";
import Navbar from "./components/Navbar/index.js";
import Sidebar from "./components/Sidebar/index.js";
import routes from "./routes";

import { setCompany } from 'store/functions/company/company'
import { load } from 'store/functions/load'

import ArchkAlerts from 'components/functional/alerts'

import checkLogin from 'utils/checkLogin'

// import launchIntercom from "utils/launchIntercom.js";
import _cases from '_functions/cases';

class Dashboard extends React.Component {
    state = {
        loaded: false,
        sidenavOpen: true,
        showMobileNav: false,
    };

    //get routes for this layout
    getRoutes = routes => routes().map((prop, key) => {
        return (
            <Route
                exact path={`/ertc${prop.path}`}
                component={prop.component}
                key={key}
            />
        )
    })

    toggleSidenav = e => {

        if (document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-show");
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.remove("noScroll");
        } else {
            document.body.classList.add("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");   
            document.body.classList.add("noScroll");
        }

        this.setState({ sidenavOpen: !this.state.sidenavOpen });
    };

    setScreenSize = () => {

        const { device } = this.props

        if(!device.isLG) {
            document.body.classList.remove("g-sidenav-show");
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");
            this.setState({ sidenavOpen: false, showMobileNav: true });
        } else {
            document.body.classList.add("g-sidenav-pinned");
            this.setState({ sidenavOpen: true, showMobileNav: false });
        }

    }

    componentDidCatch = (e) => {

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({redirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }

    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.setScreenSize)
        window.removeEventListener('focus', checkLogin)
    }

    componentDidMount = async () => {

        if(!this.props.company._id) {
            await Promise.all([
                setCompany(),
                load()
            ])
        }

        this.setScreenSize()
        this.setState({loaded: true})
        // launchIntercom(this.props.viewing_user)

        window.addEventListener('resize', this.setScreenSize)
        window.addEventListener('focus', checkLogin)

        _cases.setLoggedIn({case: this.props.selected_case._id})
    }

    render() {

        const { redirect, loaded, sidenavOpen, showMobileNav } = this.state
        const { device, company, selected_case } = this.props;

        if(redirect) return <Redirect to={redirect} />;
        if(!loaded) return <div />;
        if(company.banned) return <Redirect to="/actions/banned" />;
        if(!selected_case) return <Redirect to="/cases" />;

        return (
            <div className="layout-sidebar layout-sidebar-right">

                <ArchkAlerts />

                <Sidebar 
                    {...this.props}
                    toggleSidenav={this.toggleSidenav}
                    sidenavOpen={sidenavOpen}
                />

                <div className={`bg-toggler ${device.currentScreenWidth < 1000 && sidenavOpen ? 'show' : null}`} onClick={this.toggleSidenav}/>

                <div className="wave" />

                <div className="main-content content-wrapper" ref="mainContent" onClick={this.closeSidenav} >

                    <Navbar {...this.props} showMobileNav={showMobileNav} toggleSidenav={this.toggleSidenav} />

                    <div className="dashboard-content">
                        <Switch>
                            {this.getRoutes(routes)}
                            <Redirect from="*" to="/dashboard/home" />
                        </Switch>
                    </div>

                    <Footer />

                </div>

            </div>

        );
    }
}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	    device: state.device.info,
	    company: state.company.company,
	    load: state.load,
	    selected_case: state.system.selected_case,
	};
};

export default connect(mapStateToProps, '')(Dashboard);

