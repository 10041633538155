import { useState } from 'react';
import { CardHeader, CardFooter, FormFeedback, CardTitle, Badge, Row, Col, FormGroup, Input } from 'reactstrap';
import ConfirmationModal from 'components/functional/modals/Confirmation.js';

const FormSection4 = ({onSetChecklist, checklist, section, setSection}) => {

    const [errs, setErrs] =  useState([])
    const [showModal, setShowModal] =  useState(false)

    const [gross_revenue_quarter_1_2019, setRevQ12019]                      = useState(checklist.gross_revenue_quarter_1_2019);
    const [gross_revenue_quarter_2_2019, setRevQ22019]                      = useState(checklist.gross_revenue_quarter_2_2019);
    const [gross_revenue_quarter_3_2019, setRevQ32019]                      = useState(checklist.gross_revenue_quarter_3_2019);
    const [gross_revenue_quarter_4_2019, setRevQ42019]                      = useState(checklist.gross_revenue_quarter_4_2019);
    const [gross_revenue_quarter_1_2020, setRevQ12020]                      = useState(checklist.gross_revenue_quarter_1_2020);
    const [gross_revenue_quarter_2_2020, setRevQ22020]                      = useState(checklist.gross_revenue_quarter_2_2020);
    const [gross_revenue_quarter_3_2020, setRevQ32020]                      = useState(checklist.gross_revenue_quarter_3_2020);
    const [gross_revenue_quarter_4_2020, setRevQ42020]                      = useState(checklist.gross_revenue_quarter_4_2020);
    const [gross_revenue_quarter_1_2021, setRevQ12021]                      = useState(checklist.gross_revenue_quarter_1_2021);
    const [gross_revenue_quarter_2_2021, setRevQ22021]                      = useState(checklist.gross_revenue_quarter_2_2021);
    const [gross_revenue_quarter_3_2021, setRevQ32021]                      = useState(checklist.gross_revenue_quarter_3_2021);
    const [gross_revenue_quarter_4_2021, setRevQ42021]                      = useState(checklist.gross_revenue_quarter_4_2021);
    
    const [government_shutdown, setGovernmentShutdown]                      = useState(checklist.government_shutdown);
    const [restricted_people_in_building, setRestrictedPeopleInBuilding]    = useState(checklist.restricted_people_in_building);
    const [inability_to_attend_events, setInabilityToAttendEvents]          = useState(checklist.inability_to_attend_events);
    const [disruption_to_sales, setDisruptionToSales]                       = useState(checklist.disruption_to_sales);
    const [supply_chain_interruptions, setSupplyChainInterruptions]         = useState(checklist.supply_chain_interruptions);
    const [inability_to_access_equipment, setInabilityToAccessEquipment]    = useState(checklist.inability_to_access_equipment);
    const [limited_capacity_to_operate, setLimitedCapacityToOperate]        = useState(checklist.limited_capacity_to_operate);
    const [inability_to_work_for_vendors, setInabilityToWorkForVendors]     = useState(checklist.inability_to_work_for_vendors);
    const [reduction_in_customer_offerings, setReductionInCustomerOffering] = useState(checklist.reduction_in_customer_offerings);
    const [lowered_hours_of_operation, setLoweredHoursOfOperation]          = useState(checklist.lowered_hours_of_operation);
    const [shifting_hours_to_sanitize, setShiftingHoursToSanitize]          = useState(checklist.shifting_hours_to_sanitize);
    const [canceled_projects, setCancelledProjects]                         = useState(checklist.canceled_projects);
    const [delayed_by_supply_chain, setDelayedBySupplyChain]                = useState(checklist.delayed_by_supply_chain);
    const [other_impact, setOtherImpact]                                    = useState(checklist.other_impact);

    const setList = (_section) => {
        onSetChecklist({ 
            gross_revenue_quarter_1_2019,
            gross_revenue_quarter_2_2019,
            gross_revenue_quarter_3_2019,
            gross_revenue_quarter_4_2019,
            gross_revenue_quarter_1_2020,
            gross_revenue_quarter_2_2020,
            gross_revenue_quarter_3_2020,
            gross_revenue_quarter_4_2020,
            gross_revenue_quarter_1_2021,
            gross_revenue_quarter_2_2021,
            gross_revenue_quarter_3_2021,
            gross_revenue_quarter_4_2021, 
            government_shutdown, 
            restricted_people_in_building, 
            inability_to_attend_events, 
            disruption_to_sales, 
            supply_chain_interruptions, 
            inability_to_access_equipment, 
            limited_capacity_to_operate, 
            inability_to_work_for_vendors, 
            reduction_in_customer_offerings, 
            lowered_hours_of_operation, 
            shifting_hours_to_sanitize, 
            canceled_projects, 
            delayed_by_supply_chain, 
            other_impact, 
        }, _section)
    }

    const moveBack = (force) => {

        //gross_revenue_quarter_1_2019
        setSection(section - 1)
        setList(section - 1)
    }

    const moveForward = (force) => {

        const _errs = [];

        if(!gross_revenue_quarter_1_2019 && gross_revenue_quarter_1_2019 !== 0) _errs.push('gross_revenue_quarter_1_2019')
        if(!gross_revenue_quarter_2_2019 && gross_revenue_quarter_2_2019 !== 0) _errs.push('gross_revenue_quarter_2_2019')
        if(!gross_revenue_quarter_3_2019 && gross_revenue_quarter_3_2019 !== 0) _errs.push('gross_revenue_quarter_3_2019')
        if(!gross_revenue_quarter_4_2019 && gross_revenue_quarter_4_2019 !== 0) _errs.push('gross_revenue_quarter_4_2019')
        if(!gross_revenue_quarter_1_2020 && gross_revenue_quarter_1_2020 !== 0) _errs.push('gross_revenue_quarter_1_2020')
        if(!gross_revenue_quarter_2_2020 && gross_revenue_quarter_2_2020 !== 0) _errs.push('gross_revenue_quarter_2_2020')
        if(!gross_revenue_quarter_3_2020 && gross_revenue_quarter_3_2020 !== 0) _errs.push('gross_revenue_quarter_3_2020')
        if(!gross_revenue_quarter_4_2020 && gross_revenue_quarter_4_2020 !== 0) _errs.push('gross_revenue_quarter_4_2020')
        if(!gross_revenue_quarter_1_2021 && gross_revenue_quarter_1_2021 !== 0) _errs.push('gross_revenue_quarter_1_2021')
        if(!gross_revenue_quarter_2_2021 && gross_revenue_quarter_2_2021 !== 0) _errs.push('gross_revenue_quarter_2_2021')
        if(!gross_revenue_quarter_3_2021 && gross_revenue_quarter_3_2021 !== 0) _errs.push('gross_revenue_quarter_3_2021')
        if(!gross_revenue_quarter_4_2021 && gross_revenue_quarter_4_2021 !== 0) _errs.push('gross_revenue_quarter_4_2021')

        setErrs(_errs);
        if(_errs.length) return;

        if(gross_revenue_quarter_1_2019 === 0 && !force) return setShowModal(true) 
        if(gross_revenue_quarter_2_2019 === 0 && !force) return setShowModal(true) 
        if(gross_revenue_quarter_3_2019 === 0 && !force) return setShowModal(true) 
        if(gross_revenue_quarter_4_2019 === 0 && !force) return setShowModal(true) 
        if(gross_revenue_quarter_1_2020 === 0 && !force) return setShowModal(true) 
        if(gross_revenue_quarter_2_2020 === 0 && !force) return setShowModal(true) 
        if(gross_revenue_quarter_3_2020 === 0 && !force) return setShowModal(true) 
        if(gross_revenue_quarter_4_2020 === 0 && !force) return setShowModal(true) 
        if(gross_revenue_quarter_1_2021 === 0 && !force) return setShowModal(true) 
        if(gross_revenue_quarter_2_2021 === 0 && !force) return setShowModal(true) 
        if(gross_revenue_quarter_3_2021 === 0 && !force) return setShowModal(true) 
        if(gross_revenue_quarter_4_2021 === 0 && !force) return setShowModal(true) 

        setSection(section + 1)
        setList(section + 1)
    }

    return (

        <>

        <ConfirmationModal 
            showModal={showModal}
            toggleModal={() => setShowModal(false)}
            onConfirmation={async () => {
                moveForward(true)
            }} 
            title="Confirm Gross Revenue" 
            body={(
                <span>One or more fields for gross revenue during a quarter are set to 0. Are you sure you wish to move forward?</span>
            )}
        /> 

        <CardHeader>
            <CardTitle className="mb-0"><Badge color="success" className="mr-2">Section 4</Badge> Qualifying For ERC</CardTitle>
        </CardHeader>
        
        <CardHeader>
            <p className="mb-0 border-info p-3 rounded"><i className="fas fa-info-circle mr-2 text-info" /> There are two primary mechanisms for a business to qualify for the ERC. The more information you can complete below the better your chances of qualifying. <span className="text-italic">Qualifying under both mechanisms is NOT a requirement for accessing the ERC</span></p>
        </CardHeader>
      
        <CardHeader>
            <CardTitle className="mb-0">A) Revenue Reduction</CardTitle>
        </CardHeader>

        <CardHeader className="pl-7">

            <p>Please enter your business's GROSS REVENUE for each of the applicable quarters and years. Please leave the cell blank if the business did not exist during that quarter.</p>

            <Row className="mb-4">
                <Col md={3}>
                    <FormGroup>
                        <label className="form-control-label">2019 Q1</label>
                        <Input 
                            type="number"
                            value={gross_revenue_quarter_1_2019}
                            invalid={errs.includes('gross_revenue_quarter_1_2019') && !gross_revenue_quarter_1_2019}
                            onChange={(e) => {
                                console.log(parseFloat(e.target.value))
                                setRevQ12019(parseFloat(e.target.value))
                            }}
                            valid={gross_revenue_quarter_1_2019}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <label className="form-control-label">2019 Q2</label>
                        <Input 
                            type="number"
                            value={gross_revenue_quarter_2_2019}
                            invalid={errs.includes('gross_revenue_quarter_2_2019') && !gross_revenue_quarter_2_2019}
                            onChange={(e) => setRevQ22019(parseFloat(e.target.value))}
                            valid={gross_revenue_quarter_2_2019}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <label className="form-control-label">2019 Q3</label>
                        <Input 
                            type="number"
                            value={gross_revenue_quarter_3_2019}
                            invalid={errs.includes('gross_revenue_quarter_3_2019') && !gross_revenue_quarter_3_2019}
                            onChange={(e) => setRevQ32019(parseFloat(e.target.value))}
                            valid={gross_revenue_quarter_3_2019}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <label className="form-control-label">2019 Q4</label>
                        <Input 
                            type="number"
                            value={gross_revenue_quarter_4_2019}
                            invalid={errs.includes('gross_revenue_quarter_4_2019') && !gross_revenue_quarter_4_2019}
                            onChange={(e) => setRevQ42019(parseFloat(e.target.value))}
                            valid={gross_revenue_quarter_4_2019}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                </Col>
            </Row>

            <Row className="mb-4">
                <Col md={3}>
                    <FormGroup>
                        <label className="form-control-label">2020 Q1</label>
                        <Input 
                            type="number"
                            value={gross_revenue_quarter_1_2020}
                            invalid={errs.includes('gross_revenue_quarter_1_2020') && !gross_revenue_quarter_1_2020}
                            onChange={(e) => setRevQ12020(parseFloat(e.target.value))}
                            valid={gross_revenue_quarter_1_2020}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <label className="form-control-label">2020 Q2</label>
                        <Input 
                            type="number"
                            value={gross_revenue_quarter_2_2020}
                            invalid={errs.includes('gross_revenue_quarter_2_2020') && !gross_revenue_quarter_2_2020}
                            onChange={(e) => setRevQ22020(parseFloat(e.target.value))}
                            valid={gross_revenue_quarter_2_2020}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <label className="form-control-label">2020 Q3</label>
                        <Input 
                            type="number"
                            value={gross_revenue_quarter_3_2020}
                            invalid={errs.includes('gross_revenue_quarter_3_2020') && !gross_revenue_quarter_3_2020}
                            onChange={(e) => setRevQ32020(parseFloat(e.target.value))}
                            valid={gross_revenue_quarter_3_2020}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <label className="form-control-label">2020 Q4</label>
                        <Input 
                            type="number"
                            value={gross_revenue_quarter_4_2020}
                            invalid={errs.includes('gross_revenue_quarter_4_2020') && !gross_revenue_quarter_4_2020}
                            onChange={(e) => setRevQ42020(parseFloat(e.target.value))}
                            valid={gross_revenue_quarter_4_2020}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                </Col>
            </Row>
           
            <Row className="mb-4">
                <Col md={3}>
                    <FormGroup>
                        <label className="form-control-label">2021 Q1</label>
                        <Input 
                            type="number"
                            value={gross_revenue_quarter_1_2021}
                            invalid={errs.includes('gross_revenue_quarter_1_2021') && !gross_revenue_quarter_1_2021}
                            onChange={(e) => setRevQ12021(parseFloat(e.target.value))}
                            valid={gross_revenue_quarter_1_2021}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <label className="form-control-label">2021 Q2</label>
                        <Input 
                            type="number"
                            value={gross_revenue_quarter_2_2021}
                            invalid={errs.includes('gross_revenue_quarter_2_2021') && !gross_revenue_quarter_2_2021}
                            onChange={(e) => setRevQ22021(parseFloat(e.target.value))}
                            valid={gross_revenue_quarter_2_2021}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <label className="form-control-label">2021 Q3</label>
                        <Input 
                            type="number"
                            value={gross_revenue_quarter_3_2021}
                            invalid={errs.includes('gross_revenue_quarter_3_2021') && !gross_revenue_quarter_3_2021}
                            onChange={(e) => setRevQ32021(parseFloat(e.target.value))}
                            valid={gross_revenue_quarter_3_2021}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <label className="form-control-label">2021 Q4</label>
                        <Input 
                            type="number"
                            value={gross_revenue_quarter_4_2021}
                            invalid={errs.includes('gross_revenue_quarter_4_2021') && !gross_revenue_quarter_4_2021}
                            onChange={(e) => setRevQ42021(parseFloat(e.target.value))}
                            valid={gross_revenue_quarter_4_2021}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                </Col>
            </Row>

        </CardHeader>

        <CardHeader>
            <CardTitle className="mb-0">B) Nominal business Impact</CardTitle>
        </CardHeader>

        <CardHeader className="pl-7">
            <Row>
                <Col md={6}>

                    <FormGroup>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={`archk_checklist_government_shutdown`}
                                type="checkbox"
                                checked={government_shutdown === true}
                                onChange={() => setGovernmentShutdown(!government_shutdown)}
                            />
                            <label className="custom-control-label" htmlFor={`archk_checklist_government_shutdown`}>
                                Partial or full shutdown per government mandates
                            </label>
                        </div>
                    </FormGroup>
                    
                    <FormGroup>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={`archk_checklist_restricted_people_in_building`}
                                type="checkbox"
                                checked={restricted_people_in_building === true}
                                onChange={() => setRestrictedPeopleInBuilding(!restricted_people_in_building)}
                            />
                            <label className="custom-control-label" htmlFor={`archk_checklist_restricted_people_in_building`}>
                                Restrictions on number of people in room or building
                            </label>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={`archk_checklist_inability_to_attend_events`}
                                type="checkbox"
                                checked={inability_to_attend_events === true}
                                onChange={() => setInabilityToAttendEvents(!inability_to_attend_events)}
                            />
                            <label className="custom-control-label" htmlFor={`archk_checklist_inability_to_attend_events`}>
                                Inability to attend normal networking events and functions such as professional conferences
                            </label>
                        </div>
                    </FormGroup>
                 
                    <FormGroup>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={`archk_checklist_disruption_to_sales`}
                                type="checkbox"
                                checked={disruption_to_sales === true}
                                onChange={() => setDisruptionToSales(!disruption_to_sales)}
                            />
                            <label className="custom-control-label" htmlFor={`archk_checklist_disruption_to_sales`}>
                                Disruption to sales force's ability to function normally
                            </label>
                        </div>
                    </FormGroup>
                    
                    <FormGroup>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={`archk_checklist_supply_chain_interruptions`}
                                type="checkbox"
                                checked={supply_chain_interruptions === true}
                                onChange={() => setSupplyChainInterruptions(!supply_chain_interruptions)}
                            />
                            <label className="custom-control-label" htmlFor={`archk_checklist_supply_chain_interruptions`}>
                                Supply Chain Interruptions
                            </label>
                        </div>
                    </FormGroup>
                   
                    <FormGroup>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={`archk_checklist_inability_to_access_equipment`}
                                type="checkbox"
                                checked={inability_to_access_equipment === true}
                                onChange={() => setInabilityToAccessEquipment(!inability_to_access_equipment)}
                            />
                            <label className="custom-control-label" htmlFor={`archk_checklist_inability_to_access_equipment`}>
                                Inability to access equipment
                            </label>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={`archk_checklist_limited_capacity_to_operate`}
                                type="checkbox"
                                checked={limited_capacity_to_operate === true}
                                onChange={() => setLimitedCapacityToOperate(!limited_capacity_to_operate)}
                            />
                            <label className="custom-control-label" htmlFor={`archk_checklist_limited_capacity_to_operate`}>
                                Limited Capacity To Operate
                            </label>
                        </div>
                    </FormGroup>
               
                    <FormGroup>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={`archk_checklist_inability_to_work_for_vendors`}
                                type="checkbox"
                                checked={inability_to_work_for_vendors === true}
                                onChange={() => setInabilityToWorkForVendors(!inability_to_work_for_vendors)}
                            />
                            <label className="custom-control-label" htmlFor={`archk_checklist_inability_to_work_for_vendors`}>
                                Inability to work with vendors
                            </label>
                        </div>
                    </FormGroup>
                
                    <FormGroup>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={`archk_checklist_reduction_in_customer_offerings`}
                                type="checkbox"
                                checked={reduction_in_customer_offerings === true}
                                onChange={() => setReductionInCustomerOffering(!reduction_in_customer_offerings)}
                            />
                            <label className="custom-control-label" htmlFor={`archk_checklist_reduction_in_customer_offerings`}>
                                Reduction in services or goods offered to customers
                            </label>
                        </div>
                    </FormGroup>

                </Col>
                <Col md={6}>
                    
                    <FormGroup>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={`archk_checklist_lowered_hours_of_operation`}
                                type="checkbox"
                                checked={lowered_hours_of_operation === true}
                                onChange={() => setLoweredHoursOfOperation(!lowered_hours_of_operation)}
                            />
                            <label className="custom-control-label" htmlFor={`archk_checklist_lowered_hours_of_operation`}>
                                Cut down in hours of operation
                            </label>
                        </div>
                    </FormGroup>
                 
                    <FormGroup>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={`archk_checklist_shifting_hours_to_sanitize`}
                                type="checkbox"
                                checked={shifting_hours_to_sanitize === true}
                                onChange={() => setShiftingHoursToSanitize(!shifting_hours_to_sanitize)}
                            />
                            <label className="custom-control-label" htmlFor={`archk_checklist_shifting_hours_to_sanitize`}>
                                Shifting hours to increase sanitation of facility
                            </label>
                        </div>
                    </FormGroup>
               
                    <FormGroup>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={`archk_checklist_canceled_projects`}
                                type="checkbox"
                                checked={canceled_projects === true}
                                onChange={() => setCancelledProjects(!canceled_projects)}
                            />
                            <label className="custom-control-label" htmlFor={`archk_checklist_canceled_projects`}>
                                Projects were canceled or delayed to COVID-related disruptions
                            </label>
                        </div>
                    </FormGroup>
                   
                    <FormGroup>
                        <div className="custom-control custom-checkbox">
                            <input
                                className="custom-control-input"
                                id={`archk_checklist_delayed_by_supply_chain`}
                                type="checkbox"
                                checked={delayed_by_supply_chain === true}
                                onChange={() => setDelayedBySupplyChain(!delayed_by_supply_chain)}
                            />
                            <label className="custom-control-label" htmlFor={`archk_checklist_delayed_by_supply_chain`}>
                                Delayed production timelines caused by supply chain interruptions.
                            </label>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <label className="form-control-label">Other (Please Explain)</label>
                        <Input 
                            style={{minHeight: 120}}
                            type="textarea"
                            value={other_impact}
                            onChange={(e) => setOtherImpact(e.target.value)}
                            valid={other_impact}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>

                </Col>
            </Row>
        </CardHeader>
    
        <CardFooter>
            <Row>
                <Col xs={6} className="align-self-center">
                    <button onClick={moveBack} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left mr-2 " /> Back
                    </button>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button onClick={() => moveForward()} className="btn btn-success">Next</button>
                </Col>
            </Row>
        </CardFooter>

        </>

    )

}

export default FormSection4;