import { useState } from 'react';
import { CardHeader, CardFooter, FormFeedback, CardTitle, Badge, Row, Col, FormGroup, Input } from 'reactstrap';
import States from 'components/markup/inputs/States'

const FormSection1 = ({onSetChecklist, checklist, section, setSection}) => {

    const [errs, setErrs] = useState([])

    const [company_ein, setCompanyEin]    = useState(checklist.company_ein);
    const [company_name, setCompanyName]  = useState(checklist.company_name);
    const [company_dba, setCompanyDba]    = useState(checklist.company_dba);
    const [company_address, setAddress]   = useState(checklist.company_address);
    const [company_city, setCity]         = useState(checklist.company_city);
    const [company_state, setState]       = useState(checklist.company_state);
    const [company_zip, setZip]           = useState(checklist.company_zip);

    const moveForward = () => {

        let _errs = [];

        if(!company_ein) _errs.push('company_ein');
        if(!company_name) _errs.push('name');
        if(!company_address) _errs.push('company_address');
        if(!company_city) _errs.push('company_city');
        if(!company_state || company_state === 'false') _errs.push('company_state');
        if(!company_zip) _errs.push('company_zip');

        setErrs(_errs);
        if(_errs.length) return;

        setSection(section + 1)
        onSetChecklist({ company_ein, company_name, company_dba, company_address, company_city, company_state, company_zip }, section + 1)
    }

    return (

        <>


        <CardHeader>
            <CardTitle className="mb-0"><Badge color="success" className="mr-2">Section 1</Badge> Company Information</CardTitle>
        </CardHeader>

        <CardHeader>
            <p className="mb-0 border-info p-3 rounded"><i className="fas fa-info-circle mr-2 text-info" /> This information should match your most recent 941 payroll filing.</p>
        </CardHeader>

        <CardHeader>

            <FormGroup>
                <label className="form-control-label">Employee Identification Number (EIN)*</label>
                <Input 
                    type="text"
                    value={company_ein}
                    onChange={(e) => setCompanyEin(e.target.value)}
                    invalid={errs.includes('company_ein') &&!company_ein}
                    valid={company_ein}
                />
                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
            </FormGroup>

            <FormGroup>
                <label className="form-control-label">Legal Business Name*</label>
                <Input 
                    type="text"
                    value={company_name}
                    onChange={(e) => setCompanyName(e.target.value)}
                    invalid={errs.includes('company_name') &&!company_name}
                    valid={company_name}
                />
                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
            </FormGroup>

            <FormGroup>
                <label className="form-control-label">DBA / Trade Name (if applicable)</label>
                <Input 
                    type="text"
                    value={company_dba}
                    onChange={(e) => setCompanyDba(e.target.value)}
                    invalid={errs.includes('company_dba') &&!company_dba}
                    valid={company_dba}
                />
                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
            </FormGroup>

        </CardHeader>
    
        <CardHeader>

            <FormGroup>
                <label className="form-control-label">Mailing Address*</label>
                <Input 
                    type="text"
                    value={company_address}
                    onChange={(e) => setAddress(e.target.value)}
                    invalid={errs.includes('company_address') &&!company_address}
                    valid={company_address}
                />
                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
            </FormGroup>

            <Row>

                <Col md={4}>
                    <FormGroup>
                        <label className="form-control-label">City*</label>
                        <Input 
                            type="text"
                            value={company_city}
                            onChange={(e) => setCity(e.target.value)}
                            invalid={errs.includes('company_city') &&!company_city}
                            valid={company_city}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                </Col>

                <Col md={4}>
                    <FormGroup>
                        <label className="form-control-label">State*</label>
                        <Input 
                            type="select"
                            value={company_state}
                            onChange={(e) => setState(e.target.value)}
                            invalid={errs.includes('company_state') && (!company_state || company_state === 'false') }
                            valid={company_state}
                        >
                            <option value="false"></option>
                            <States />
                        </Input>
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                </Col>

                <Col md={4}>
                    <FormGroup>
                        <label className="form-control-label">Zip*</label>
                        <Input 
                            type="text"
                            value={company_zip}
                            onChange={(e) => setZip(e.target.value)}
                            invalid={errs.includes('company_zip') &&!company_zip}
                            valid={company_zip}
                        />
                        <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
                    </FormGroup>
                </Col>

            </Row>

        </CardHeader>

        <CardFooter className="text-right">
            <button onClick={moveForward} className="btn btn-success">Next</button>
        </CardFooter>

        </>

    )

}

export default FormSection1;