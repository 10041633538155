import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Card } from 'reactstrap';

import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5'
import Section6 from './Section6'
import Section7 from './Section7'

import Circle from 'components/markup/loading/Circle';

import _cases from '_functions/cases'

import { setSelectedCase } from 'store/functions/system/system'
import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

const Form = ({selected_case}) => {

    const [redirect, setRedirect] = useState(false);
    const [err, setErr] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [section, setSection] = useState(1);
    
    const [checklist, setChecklist] = useState({
        company_ein: '',
        company_name: '',
        company_dba: '',
        company_address: '',
        company_city: '',
        company_state: '',
        company_zip: '',

        rep_name : '',
        rep_title : '',
        rep_email : '',
        rep_phone : '',

        employees_quarter_4_2020: 0,
        employees_quarter_1_2021: 0,
        employees_quarter_2_2021: 0,
        employees_quarter_3_2021: 0,

        gross_revenue_quarter_1_2019: 0,
        gross_revenue_quarter_2_2019: 0,
        gross_revenue_quarter_3_2019: 0,
        gross_revenue_quarter_4_2019: 0,
        gross_revenue_quarter_1_2020: 0,
        gross_revenue_quarter_2_2020: 0,
        gross_revenue_quarter_3_2020: 0,
        gross_revenue_quarter_4_2020: 0,
        gross_revenue_quarter_1_2021: 0,
        gross_revenue_quarter_2_2021: 0,
        gross_revenue_quarter_3_2021: 0,
        gross_revenue_quarter_4_2021: 0,

        government_shutdown: false,
        restricted_people_in_building: false,
        inability_to_attend_events: false,
        disruption_to_sales: false,
        supply_chain_interruptions: false,
        inability_to_access_equipment: false,
        limited_capacity_to_operate: false,
        inability_to_work_for_vendors: false,
        reduction_in_customer_offerings: false,
        lowered_hours_of_operation: false,
        shifting_hours_to_sanitize: false,
        canceled_projects: false,
        delayed_by_supply_chain: false,
        other_impact: '',

        ppp_round_1: false,
        ppp_round_1_loan_amount: 0,
        ppp_round_1_loan_amount_spent: 0,
        ppp_round_1_start: '',
        ppp_round_1_end: '',
        ppp_round_2: false,
        ppp_round_2_loan_amount: 0,
        ppp_round_2_loan_amount_spent: 0,
        ppp_round_2_start: '',
        ppp_round_2_end: '',
        
        business_owners: '',
        business_owners_w2: '',

        social_security_wages_quarter_4_2020: 0,
        social_security_wages_quarter_1_2021: 0,
        social_security_wages_quarter_2_2021: 0,
        social_security_wages_quarter_3_2021: 0,

    });

    const onSetChecklist = useCallback(async (values, _section) => {
        const _checklist = JSON.parse(JSON.stringify(checklist));
        const data = {..._checklist, ...values}
        setChecklist(data)

        let submit = _section === 8;
        if(submit) toggleStandardLoader(true);

        const saved = await _cases.saveChecklist({
            ...data, 
            case: selected_case._id, 
            last_section: _section ? _section : 1,
            submit: submit
        })
        if(submit) {
            toggleStandardLoader(false);
            if(saved.success) {
                const _foundCase = await _cases.findById(selected_case._id)
                toggleStandardLoader(false);
                toggleAlertBS(false, `Your Checklist has been submitted and is now under review by our staff.`)
                setRedirect('/ertc')
                if(_foundCase.data) setSelectedCase(_foundCase.data)
            } else {
                toggleStandardLoader(false);
                toggleAlertBS(true, `Something went wrong, please try again.`)
            }
        }

    }, [checklist, selected_case._id])

    const fetchChecklist = useCallback(async () => {
        const _checklist = await _cases.findById(selected_case._id)

        if(!_checklist.success) return setErr('true');

        if(_checklist.data) {
            let section = _checklist.data.last_section - 1;
            if(section < 1) section = 1
            setSection(section)
            setChecklist(_checklist.data)
        }
        setLoaded(true)
    }, [selected_case._id])

    useEffect(() => {
        window.scrollTo(0,0)
    }, [section])

    useEffect(() => {
        fetchChecklist();
    }, [fetchChecklist])

    if(redirect) return <Redirect to={redirect} />
    if(err) return <div className="alert alert-danger"><i className="fas fa-exclamation-triangle " /> This page is currently unavailable.</div>
    if(!loaded) return <div className="py-6"><Circle /></div>

    return (
        <div className="mb-6">

            <h2 className="display-4">Financial Information Checklist</h2>
            
            <Card>

                {section === 1 ? (
                    <Section1 
                        checklist={checklist}
                        onSetChecklist={onSetChecklist}
                        section={section}
                        setSection={setSection}
                    />
                ) : section === 2 ? (
                    <Section2 
                        checklist={checklist}
                        onSetChecklist={onSetChecklist}
                        section={section}
                        setSection={setSection}
                    />
                ) : section === 3 ? (
                    <Section3
                        checklist={checklist}
                        onSetChecklist={onSetChecklist}
                        section={section}
                        setSection={setSection}
                    />
                ) : section === 4 ? (
                    <Section4
                        checklist={checklist}
                        onSetChecklist={onSetChecklist}
                        section={section}
                        setSection={setSection}
                    />
                ) : section === 5 ? (
                    <Section5
                        checklist={checklist}
                        onSetChecklist={onSetChecklist}
                        section={section}
                        setSection={setSection}
                    />
                ) : section === 6 ? (
                    <Section6
                        checklist={checklist}
                        onSetChecklist={onSetChecklist}
                        section={section}
                        setSection={setSection}
                    />
                ) : section === 7 || section === 8 ? (
                    <Section7
                        checklist={checklist}
                        onSetChecklist={onSetChecklist}
                        section={section}
                        setSection={setSection}
                    />
                ) : null}

            </Card>

        </div>
    )

}


const mapStateToProps = state => {
	return {
	    selected_case: state.system.selected_case,
	};
};

export default connect(mapStateToProps, '')(Form);