const renderCaseName = (text) => {

    if(!text) return undefined
    text = text.replace(' - ertc', '')

    try {

        let string

        const split = text.split(' ')

        split.forEach(s => {
            s = s.replace(/^./, s[0].toUpperCase());
            string = string ? string + ' ' + s : s
        })

        string = string.trim();
        if(string.includes('Llc'))  string = string.replace('Llc', 'LLC')
        if(string.includes('Pllc')) string = string.replace('Pllc', 'PLLC')

        return string

   } catch(e) {

        console.log(e)
        return undefined

   }

}

export default renderCaseName;