import React, { useState, useCallback, useEffect } from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Form, FormGroup, Container, Input } from 'reactstrap';
import keys from 'keys';

import Circle from 'components/markup/loading/Circle';

import _contact_texts from '_functions/contact_texts';
import { toggleStandardLoader, toggleAlertBS } from 'store/functions/system/system';

const OptOutEmail = ({match}) => {

    const [err, setErr] = useState(false);
    const [opt_out_email, setOptOutEmail] = useState(false);
    const [opt_out_text, setOptOutText] = useState(false);
    const [data, setData] = useState(false);
    const [reply, setReply] = useState('')
    const [textError, setTextError] = useState('')
    const [success, setSuccess] = useState('')

    const onInputToggle = useCallback((stateName) => {
        if(stateName === 'opt_out_email') {
            setOptOutEmail(!opt_out_email)
        } else {
            setOptOutText(!opt_out_text)
        }
    }, [opt_out_email, opt_out_text])

    const onSubmit = useCallback(async () => {
        toggleStandardLoader(true)
        const updated = await _contact_texts.reply(match.params._id, { reply, email: match.params.email })
        toggleStandardLoader(false)

        if(updated.success) {
            setSuccess(true)
        } else {
            setTextError(updated.message)
        }

    }, [match.params._id, match.params.email, reply])

    const fetchContact = useCallback(async () => {
        const data = await _contact_texts.findById(match.params._id)
        if(data.data) {
            setData(data.data)
        } else {
            setErr(true)
        }
    }, [match.params._id])

    useEffect(() => {
        fetchContact()
    }, [fetchContact])

    if(err) return <p className="text-center mt-4">**The link you are currently on is no longer valid**</p>
    if(textError) return <p className="text-center mt-4"><i className="fas fa-exclamation-triangle text-danger mr-2 " /> {textError}</p>
    if(success) return <p className="text-center mt-4"><i className="fas fa-check text-success mr-2 " /> Text Replied Successfully</p>
    if(!data) return <div className="py-6"><Circle /></div>

    return (
        <Container className="pt-5" style={{maxWidth: 450}}>

            <Card>

                <CardHeader>
                    <CardTitle className="mb-0">Quick Reply</CardTitle>
                </CardHeader>

                <CardHeader>
                    <small className="text-capitalize">{data.contact.given_name} {data.contact.family_name} Messaged:</small>
                    <p className="mb-0">"{data.value}"</p>
                </CardHeader>

                <CardBody>
                    <Form>

                        <FormGroup className="mb-0">
                            <label className="form-control-label">Send Reply</label>
                            <Input 
                                type="text"
                                value={reply}
                                onChange={(e) => setReply(e.target.value)}
                            />
                        </FormGroup>

                    </Form>
                </CardBody>

                <CardFooter className="text-right">
                    <button onClick={onSubmit} className="btn btn-outline-warning">Send Reply</button>
                </CardFooter>

            </Card>

            <p className="text-sm mt-3"><a target="_blank" rel="noopener noreferrer" href={`${keys.APP_URL}/dashboard/contacts/view/${data.contact._id}?nav=6`}>** To reply within mybizheadlaw.com click here.</a></p>

        </Container>
    )

}



export default OptOutEmail;