import PropTypes from 'prop-types'

import { Modal } from "reactstrap";

import DragAndDrop from 'components/functional/uploads/DragAndDrop';

import ConfirmationModal from 'components/functional/modals/Confirmation.js';
import ModalToggler from 'components/functional/modals/Toggler'

import _cases from '_functions/cases'

import renderCaseName from 'utils/renderCaseName'

const ModalUpload = ({showModal, toggleModal, missingDoc, foundCase, onUpload, onUploadStarted, onError, fetchCase}) => (
    <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={toggleModal}
        size="lg"
    >

        <div className="modal-header">
            <h5 className="modal-title">Upload {missingDoc ? missingDoc.name : ''}</h5>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
            >
                <span aria-hidden={true}>×</span>
            </button>
        </div>

        <div className="modal-body">

            {missingDoc ? (
                <div>
                    {missingDoc.name === 'Payroll Tax Returns 2020 Q4' ? (
                        <p><i className="fas fa-exclamation-triangle mr-2 text-danger" /> Make sure the document you upload is a <b className="text-underline text-dark">SINGLE PDF</b> containing your Payroll Tax Returns for <b className="text-underline text-dark">Quarter 4 2020</b></p>
                    ) : missingDoc.name === 'Payroll Tax Returns 2021 Q1' ? (
                        <p><i className="fas fa-exclamation-triangle mr-2 text-danger" /> Make sure the document you upload is a <b className="text-underline text-dark">SINGLE PDF</b> containing your Payroll Tax Returns for <b className="text-underline text-dark">Quarter 1 2021</b></p>
                    ) : missingDoc.name === 'Payroll Tax Returns 2021 Q2' ? (
                        <p><i className="fas fa-exclamation-triangle mr-2 text-danger" /> Make sure the document you upload is a <b className="text-underline text-dark">SINGLE PDF</b> containing your Payroll Tax Returns for <b className="text-underline text-dark">Quarter 2 2021</b></p>
                    ) : missingDoc.name === 'Payroll Tax Returns 2021 Q3' ? (
                        <p><i className="fas fa-exclamation-triangle mr-2 text-danger" /> Make sure the document you upload is a <b className="text-underline text-dark">SINGLE PDF</b> containing your Payroll Tax Returns for <b className="text-underline text-dark">Quarter 3 2021</b></p>
                    ) : missingDoc.name === 'Payroll Summary' ? (
                        <p><i className="fas fa-exclamation-triangle mr-2 text-danger" /> Make sure this document is in <b className="text-underline text-dark">.xls (An Excel File)</b> or <b className="text-underline text-dark">.csv</b> format with date range 3/15/20 through 9/30/21</p>
                    ) : missingDoc.name === 'Small Business Administration (SBA) Form 3508 - PPP Round 1' ? (
                        <p>
                            <i className="fas fa-exclamation-triangle mr-2 text-danger" />
                            If you did not receive PPP Round 1{' '}
                            <ModalToggler 
                                component={ConfirmationModal} 
                                onConfirmation={async () => {
                                    toggleModal()
                                    const saved = await _cases.saveChecklist({
                                        ppp_round_1: false,
                                        _id: foundCase._id, 
                                        case: foundCase._id, 
                                    })
                                    fetchCase();
                                }} 
                                title="PPP Round 1" 
                                body={(
                                    <span>Are you sure you wish to mark that you did not receive PPP Round 1?</span>
                                )}
                            >
                                <b className="text-underline cursor-pointer">CLICK HERE</b>.
                            </ModalToggler> 
                            {' '}Only do this if you did not receive PPP Round 1, you will not need to upload this document if that is the case. 
                        </p>
                    ) : missingDoc.name === 'Small Business Administration (SBA) Form 3508 - PPP Round 2' ? (
                        <p>
                            <i className="fas fa-exclamation-triangle mr-2 text-danger" />
                            If you did not receive PPP Round 2{' '}
                            <ModalToggler 
                                component={ConfirmationModal} 
                                onConfirmation={async () => {
                                    toggleModal()
                                    const saved = await _cases.saveChecklist({
                                        ppp_round_2: false,
                                        _id: foundCase._id, 
                                        case: foundCase._id, 
                                    })
                                    fetchCase();
                                }} 
                                title="PPP Round 1" 
                                body={(
                                    <span>Are you sure you wish to mark that you did not receive PPP Round 2?</span>
                                )}
                            >
                                <b className="text-underline cursor-pointer">CLICK HERE</b>.
                            </ModalToggler> 
                            {' '}Only do this if you did not receive PPP Round 2, you will not need to upload this document if that is the case. 
                        </p>
                    ) : null}
                </div>
            ) : null}

            {missingDoc ? (
                <DragAndDrop
                    zone_id="archk-portal-upload"
                    url={`/v1/api/documents`}
                    onError={onError}
                    defaultValue={foundCase.name ? `${renderCaseName(foundCase.name.replace(/\./g, ''))} - ${missingDoc.name} (${missingDoc.documents.length + 1})` : `${missingDoc.name} (${missingDoc.documents.length + 1})`}
                    uploadOne={true}
                    onUploadStarted={() => {
                        toggleModal()
                        onUploadStarted()
                    }}
                    onUpload={onUpload}
                    extraData={{
                        case: foundCase._id, 
                        missing_doc: missingDoc._id,
                        set_pending: true
                    }}
                />
            ) : null}
            
        </div>

    </Modal>
)

ModalUpload.propTypes = {
    toggleModal   : PropTypes.func.isRequired,
    showModal     : PropTypes.bool.isRequired,
}

export default ModalUpload