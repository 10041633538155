import React from "react";
import { connect } from 'react-redux';
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";

import keys from 'keys';

import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Badge } from "reactstrap";

class Sidebar extends React.Component {
  
    state = {
        collapseOpen: false,
        routes: [
            
            { divider: 'My Cases' },
            {
                path: "/cases?clear=true",
                name: "All Cases",
                icon: "fas fa-arrow-left text-warning",
            },     

            { divider: 'My Account' },
        
            {
                path: "/auth/logout",
                name: "Logout",
                icon: "fas fa-sign-out-alt text-warning",
            },
                    
        ]
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute = routeName => {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };

    // toggles collapse between opened and closed (true/false)
    toggleCollapse = () => {
        this.setState({ collapseOpen: !this.state.collapseOpen });
    };
    // closes the collapse
    closeCollapse = () => {
        this.setState({ collapseOpen: false });
    };
    // this creates the intial state of this component based on the collapse routes
    // that it gets through this.props.routes
    getCollapseStates = routes => {
        let initialState = {};
        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState
                };
            }
            return null;
        });
        return initialState;
    };
    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
    getCollapseInitialState(routes) {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.href.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    }

    // this is used on mobile devices, when a user navigates
    // the sidebar will autoclose
    closeSidenav = () => {
        if (window.innerWidth < 1000) {
            this.props.toggleSidenav();
        }
    };

    // this function creates the links and collapses that appear in the sidebar (left menu)
    createLinks = routes => {
        return routes.map((prop, key) => {

            const finishedDocuments = this.props.ertc_pct_documents === 100;
            const finishedFinancialInfoChecklist = this.props.ertc_pct_financial_info_checklist === 100;
            const finishedClientServices = this.props.ertc_pct_client_services === 100;

            if(prop.icon) {
                prop.icon +=  
                prop.path === '/ertc' && finishedFinancialInfoChecklist && finishedDocuments && finishedClientServices ? ' text-success' :
                prop.path === '/ertc/checklist' && finishedFinancialInfoChecklist ? ' text-success' : 
                prop.path === '/ertc/documents' && finishedDocuments ? ' text-success' : 
                prop.path === '/ertc/client_services' && finishedClientServices ? ' text-success' : 
                ''
            }
           
            if(prop.divider) return <li key={key} className="divider"> <h6 className="navbar-heading p-0 mb-0 text-muted">{prop.divider}</h6> </li>

            if (prop.collapse) {
                var st = {};
                st[prop["state"]] = !this.state[prop.state];
                return (
                    <NavItem key={key}>
                        <NavLink
                            href="#blank"
                            data-toggle="collapse"
                            aria-expanded={this.state[prop.state]}
                            className={classnames({ active: this.getCollapseInitialState(prop.views) })}
                            onClick={e => { e.preventDefault(); this.setState(st); }}
                        >
                            {prop.icon ? <i className={prop.icon} /> : null}
                            <span className="nav-link-text">{prop.name}</span>
                        </NavLink>
                        <Collapse isOpen={this.state[prop.state]}>
                            <Nav className="nav-sm flex-column">
                                {this.createLinks(prop.views)}
                            </Nav>
                        </Collapse>
                    </NavItem>
                );
            }

            return (
                <NavItem
                    className={this.activeRoute(prop.path, prop.exact)}
                    key={key}
                >
                    <NavLink
                        to={prop.path}
                        activeClassName="active"
                        onClick={this.closeSidenav}
                        tag={NavLinkRRD}
                        exact={prop.exact}
                    >
                        {prop.icon !== undefined ? (
                            <>
                            <i className={prop.icon} />{' '}
                            <span className="nav-link-text font-weight-bold">
                                <span 
                                    className={
                                        prop.path === '/ertc' && finishedFinancialInfoChecklist && finishedDocuments && finishedClientServices ? 'text-line-through' : 
                                        prop.path === '/ertc/checklist' && finishedFinancialInfoChecklist ? 'text-line-through' : 
                                        prop.path === '/ertc/documents' && finishedDocuments ? 'text-line-through' : 
                                        prop.path === '/ertc/client_services' && finishedClientServices ? 'text-line-through' : 
                                        ''
                                    }
                                >
                                    {prop.name}
                                </span>
                            </span>
                            </>
                        ) : (
                            prop.name
                        )}
                        {prop.counter && (
                            <span className="ml-auto">
                                <Badge style={{width: 28}} color={prop.counter < 10 ? 'success' : prop.counter < 99 ? 'warning' : 'danger'}>
                                    {prop.counter >= 99 ? '99+' : prop.counter}
                                </Badge>
                            </span>
                        )}
                    </NavLink>
                </NavItem>
            );
        });
    };

    componentDidMount = () => {
        this.getCollapseStates(this.state.routes);
    }
  
    render() {

        const { sidenavOpen } = this.props;
        const { routes } = this.state;

        const scrollBarInner = (
            <div className="scrollbar-inner">
                <div className="sidenav-header d-flex align-items-center bg-off-black">

                    <NavbarBrand to="/ertc" tag={Link} >
                        <img alt={"Logo"} className="navbar-brand-img" src={keys.LOGO} />
                    </NavbarBrand>

                    <div className="ml-auto">
                        <Link to="/dashboard">
                            <div
                                className={classnames("sidenav-toggler d-xl-block", {active: sidenavOpen })}
                            >
                            </div>
                        </Link>
                    </div>

                </div>

                <div className="navbar-inner">
                    <Collapse navbar isOpen={true}>
                        <Nav navbar>
                            {this.createLinks(routes)}
                        </Nav>
                    </Collapse>
                </div>
            </div>
        );

        return (
            <Navbar
                className="sidenav navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white z-depth-1"
            >
                {navigator.platform.indexOf("Win") > -1 ? (
                    <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
                ) : (
                    scrollBarInner
                )}
        </Navbar>
        );
    }

}


const mapStateToProps = state => {
	return {
	    company: state.company.company,
	    viewing_user: state.auth.viewing_user,
	    cases: state.load.cases,
        ertc_pct_documents: state.system.ertc_pct_documents,
        ertc_pct_financial_info_checklist: state.system.ertc_pct_financial_info_checklist,
        ertc_pct_client_services: state.system.ertc_pct_client_services,
        contact_alerts: state.system.contact_alerts,

	};
};


export default connect(mapStateToProps, '')(Sidebar);
