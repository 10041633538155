import LOGO from 'assets/img/brand/BizHeadLogoCut.png'

let keys = {
    LOGO,
    LOGO_WHITE: LOGO,
    COMPANY: 'Biz Head Law',
};

const HOSTNAME = window.location.hostname

//using production keys
if(HOSTNAME === 'portal.mybizheadlaw.com') {

    keys = {

        ...keys,

        SYSTEM_API_KEY            : 'architeck-58163098451',
        MX_CLIENT_ID              : '',
        API_URL                   : 'https://portal-api.mybizheadlaw.com',
        APP_URL                   : 'https://mybizheadlaw.com',

    }

//using staging keys
} else if(HOSTNAME !== 'localhost') {

    keys = {

        ...keys,

        SYSTEM_API_KEY            : 'architeck-946154718',
        MX_CLIENT_ID              : '4f7d5f10-c1fb-4d43-b034-7d8d2296e1ba',
        API_URL                   : 'https://staging.portal-api.mybizheadlaw.com',
        APP_URL                   : 'https://staging.mybizheadlaw.com',


    }

//using development keys
} else {

   
    keys = {
        ...keys,
        API_URL               : 'http://localhost:5011',
        AUTH_URL               : 'http://localhost:3010',
        APP_URL               : 'http://localhost:3011',
        ADMIN_URL             : 'http://localhost:3012',
        CLIENT_URL            : 'http://localhost:3013',
        BOOK_URL            : 'http://localhost:3014',
        PORTAL_URL            : 'http://localhost:3015',

        SYSTEM_API_KEY        : 'architeck-4874517265',
        env                   : 'dev',


    }

}

export default keys
