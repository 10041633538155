import { useCallback, useState } from 'react';
import { CardHeader, CardFooter, FormFeedback, CardTitle, Badge, Row, Col, FormGroup, Input } from 'reactstrap';

const FormSection2 = ({onSetChecklist, checklist, section, setSection}) => {

    const [errs, setErrs] = useState([])

    const [business_owners, setBusinessOwners]           = useState(checklist.business_owners);
    const [business_owners_w2, setBusinessOwnersW2]           = useState(checklist.business_owners_w2);


    const moveBack = useCallback(() => {
        setSection(section - 1)
        onSetChecklist({ business_owners, business_owners_w2 }, section - 1)
    }, [business_owners, business_owners_w2, section, setSection, onSetChecklist])

    const moveForward = useCallback(() => {
        let _errs = [];

        if(!business_owners) _errs.push('business_owners');

        setErrs(_errs);
        if(_errs.length) return;

        setSection(section + 1)
        onSetChecklist({ business_owners, business_owners_w2 }, section + 1)
    }, [business_owners, business_owners_w2, section, setSection, onSetChecklist ])

    return (

        <>

        <CardHeader>
            <CardTitle className="mb-0"><Badge color="success" className="mr-2">Section 6</Badge> Owners & Family Members</CardTitle>
        </CardHeader>
        
        <CardHeader>
            <p className="mb-0 border-info p-3 rounded"><i className="fas fa-info-circle mr-2 text-info" /> Calculation of the ERC requires that business owners and family members be taken into consideration.</p>
        </CardHeader>

        <CardHeader>

            <FormGroup>
                <label className="form-control-label">Please list the names of all owners of the business below* (1 name per line)</label>
                <Input 
                    style={{minHeight: 120}}
                    type="textarea"
                    value={business_owners}
                    onChange={(e) => setBusinessOwners(e.target.value)}
                    invalid={errs.includes('business_owners') &&!business_owners}
                    valid={business_owners}
                />
                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
            </FormGroup>
           
            <FormGroup>
                <label className="form-control-label">If any W2 employees of the business are related to any of the business owners list the names of those employees below (1 name per line)</label>
                <Input 
                    style={{minHeight: 120}}
                    type="textarea"
                    value={business_owners_w2}
                    onChange={(e) => setBusinessOwnersW2(e.target.value)}
                    invalid={errs.includes('business_owners_w2') &&!business_owners_w2}
                    valid={business_owners_w2}
                />
                <FormFeedback><i className="fas fa-arrow-up mr-2 " /> Field must not be left blank <i className="fas fa-arrow-up ml-2 " /></FormFeedback>
            </FormGroup>

        </CardHeader>
    
        <CardFooter>
            <Row>
                <Col xs={6} className="align-self-center">
                    <button onClick={moveBack} className="btn btn-outline-warning">
                        <i className="fas fa-arrow-left mr-2 " /> Back
                    </button>
                </Col>
                <Col xs={6} className="align-self-center text-right">
                    <button onClick={moveForward} className="btn btn-success">Next</button>
                </Col>
            </Row>
        </CardFooter>

        </>

    )

}

export default FormSection2;