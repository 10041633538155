import { connect } from 'react-redux';
import { CardHeader, Card, CardTitle, CardBody } from "reactstrap";
import { Link } from 'react-router-dom';

import ChecklistImage from '../assets/ChecklistImage.svg'
import ConfirmedImage from 'assets/img/misc/ConfirmedImage.svg'

import cardStyles from '../../_styles/cards'

const Dashboard = ({ertc_pct_financial_info_checklist}) => {

    const finished = ertc_pct_financial_info_checklist === 100;

    return (
        <Card style={cardStyles} className={finished ? 'main-card border-success' : 'main-card border'}>
            
            <CardHeader>
                <CardTitle className="mb-0">Financial Info Checklist <span className="float-right">({ertc_pct_financial_info_checklist}%)</span> </CardTitle>
            </CardHeader>
            
            <CardHeader className="bg-secondary image-container" style={{maxHeight: finished ? 146 : 'auto'}}>
                <div className="text-center">
                    <img src={finished ? ConfirmedImage : ChecklistImage} alt="..." className={`w-50 ${finished ? 'ml-7': ''}`} />
                </div>
            </CardHeader>

            <CardHeader>
                {finished ? (
                    <p className="text-sm mb-0">You're all set here! Your checklist has been completed and has been processed by our team. There is no further action that you need to take in this area of your case.</p>
                ) : (
                    <p className="text-sm mb-0">Before you case can get filed we need some information about your current situation. Please use the button below to finish filling out the Biz Head Law checklist</p>
                )}
            </CardHeader>
            
            <CardBody>
                {finished ? (
                    <button disabled={true} className="btn btn-success btn-block btn-lg"><i className="fas fa-check mr-2 " /> Finished</button>
                ) : (
                    <Link to="/ertc/checklist" className="btn btn-info btn-block btn-lg"><i className="fas fa-edit mr-2 " /> Get Started</Link>
                )}
            </CardBody>
        </Card>
    )

}


const mapStateToProps = state => {
	return {
	    ertc_pct_financial_info_checklist: state.system.ertc_pct_financial_info_checklist,
	};
};

export default connect(mapStateToProps, '')(Dashboard);