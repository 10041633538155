import { useCallback, useState } from 'react';

import keys from 'keys';
import React from "react";
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { CardFooter, CardHeader, CardTitle, Card, CardBody, Col, Container, FormGroup, Input, Row } from "reactstrap";
import { setViewingUser } from 'store/functions/auth/auth';
import { getUrlParameter } from 'utils/urls';

import auth from '_functions/auth';

import Circle from 'components/markup/loading/Circle'

const LoginCompanyMFA = ({mfaFields}) => {

    const [err, setErr] = useState(false);
    const [view, setView] = useState('select method');
    const [code, setCode] = useState({});

    const [redirect, setRedirect] = useState('');
    const [loading, setLoading] = useState('');

    const focusInput = useCallback((num) => {
        let timesTried = 0;

        const focus = () => {
            if(timesTried > 20) return;
            const input = document.getElementById(`code-input-${num}`);
            if(input) return input.focus();

            setTimeout(() => {
                timesTried++;
                focus();
            }, 100)
        }
        focus()
    }, [])    

    const onVerify = useCallback(async (_code) => {

        // if code is passed in use that code, else use state code if button is pressed
        const CODE = _code ? _code : code;

        if(!CODE[1] || !CODE[2] || !CODE[3] || !CODE[4] || !CODE[5] || !CODE[6]) return;
        setErr(false);
        setLoading(true)

        let fullCode = '';
        Object.keys(CODE).forEach(key => fullCode += CODE[key]);

        const verified = await auth.mfa.verify({
            _id: mfaFields.mfa_fields._id,
            company: mfaFields.company._id,
            code: fullCode,
            email: mfaFields.email,
            password: mfaFields.password,
        })


        if(!verified.success) {
            setLoading(false)
            focusInput(6);
            return setErr(verified.message);
        }

        // if a logged in user is not staff return
        if(!verified.data.is_staff) {
            setLoading(false)
            return setErr({error: `Unauthorized`});
        }

        await setViewingUser();
        const redirect = getUrlParameter('redirect')

        setRedirect(redirect ? redirect : '/dashboard/home')

    }, [mfaFields, code, focusInput])

    const onSetCode = useCallback((num, value) => {
        const _code = JSON.parse(JSON.stringify(code));
        _code[num] = value ? value.slice(-1) : value;
        setCode(_code)

        const nextInput = document.getElementById(`code-input-${num + 1}`);
        if(nextInput) return nextInput.focus();

        onVerify(_code);
    }, [code, onVerify])

    const onKeyDown = useCallback((num, e) => {
        if (e.key === 'Delete' || e.key === 'Backspace' || e.code === 8) {

            e.preventDefault();

            const _code = JSON.parse(JSON.stringify(code));
            _code[num] = '';
            setCode(_code)

            const lastInput = document.getElementById(`code-input-${num - 1}`);
            if(lastInput) lastInput.focus();
        }
    }, [code])

    const onResend = useCallback(() => {
        setView('select method');
        setErr(false);
        setCode({})
    }, [])

    const sendMFA = useCallback(async(type) => {

        setLoading(true);

        const sent = await auth.mfa.send({
            _id: mfaFields.mfa_fields._id,
            company: mfaFields.company._id,
            type
        })

        setLoading(false);

        if(sent.success) {
            setView('verify')
            focusInput(1);
        } else {
            setErr(sent.message)
        }

    }, [mfaFields.mfa_fields._id, mfaFields.company._id, focusInput])

    if(redirect) return <Redirect to={redirect} />

    return (

        <>
        <Helmet>
            <title>MFA</title>
            <meta name="description" content="MFA" />
        </Helmet>

        <div className="header  py-7 py-lg-8 pt-lg-9">
            <Container>
                <div className="header-body text-center mb-7">
                    <Row className="justify-content-center">
                        <Col className="px-5" lg="6" md="8" xl="5">

                            <img style={{width: 250}} alt="..."  src={keys.LOGO_WHITE} />
                            <p className="font-weight-bold mt-3">Secured Access</p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>

        <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">

                    {loading ? (
                        <Circle />
                    ) : view === 'select method' ? (
                         <Card className="bg-white border-0 mb-0 z-depth-2" >

                            <CardHeader>
                                <CardTitle className="mb-0">Select MFA Method</CardTitle>
                            </CardHeader>
    
                            {mfaFields.mfa_fields.email ? (
                                <CardHeader className="mfa-option" onClick={() => sendMFA('email')}>
                                    <Row>
                                        <Col xs={10} className="align-self-center">
                                            <h4 className="mb-0">Email </h4>
                                            <p className="text-sm mb-0">Send a security code to the email: {mfaFields.mfa_fields.email}</p>
                                        </Col>
                                        <Col xs={2} className="align-self-center text-right">
                                            <i className="fas fa-paper-plane ml-2 float-right text-info" /> 
                                        </Col>
                                    </Row>
                                </CardHeader>
                            ) : null}
    
                            {mfaFields.mfa_fields.phone ? (
                                <CardHeader className="mfa-option" onClick={() => sendMFA('phone')}>
                                    <Row>
                                        <Col xs={10} className="align-self-center">
                                            <h4 className="mb-0">Phone </h4>
                                            <p className="text-sm mb-0">Send a security code to the device ending in: {mfaFields.mfa_fields.phone}</p>
                                        </Col>
                                        <Col xs={2} className="align-self-center text-right">
                                            <i className="fas fa-mobile ml-2 float-right text-warning" /> 
                                        </Col>
                                    </Row>
    
                                </CardHeader>
                            ) : null}
    
                        </Card>
                    ) : (
                        <Card className="bg-white border-0 mb-0 z-depth-2" >

                            <CardHeader>
                                <CardTitle className="mb-0">Verify Code</CardTitle>
                            </CardHeader>

                            <CardBody className="bg-secondary">
                                <p className="text-sm mb-0">Please Enter Your Authorization Code</p>
                            </CardBody>

                            <CardBody className="mfa-auth-code">
                                <FormGroup className="mb-0">

                                    <Row>
                                        <Col xs={2}>
                                            <Input 
                                                id="code-input-1"
                                                onKeyDown={(e) => onKeyDown(1, e)}
                                                type="text"
                                                value={code[1] || ''}
                                                onChange={(e) => onSetCode(1, e.target.value)}
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <Input 
                                                id="code-input-2"
                                                onKeyDown={(e) => onKeyDown(2, e)}
                                                type="text"
                                                value={code[2] || ''}
                                                onChange={(e) => onSetCode(2, e.target.value)}
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <Input 
                                                id="code-input-3"
                                                onKeyDown={(e) => onKeyDown(3, e)}
                                                type="text"
                                                value={code[3] || ''}
                                                onChange={(e) => onSetCode(3, e.target.value)}
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <Input 
                                                id="code-input-4"
                                                onKeyDown={(e) => onKeyDown(4, e)}
                                                type="text"
                                                value={code[4] || ''}
                                                onChange={(e) => onSetCode(4, e.target.value)}
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <Input 
                                                id="code-input-5"
                                                onKeyDown={(e) => onKeyDown(5, e)}
                                                type="text"
                                                value={code[5] || ''}
                                                onChange={(e) => onSetCode(5, e.target.value)}
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <Input 
                                                id="code-input-6"
                                                onKeyDown={(e) => onKeyDown(6, e)}
                                                type="text"
                                                value={code[6] || ''}
                                                onChange={(e) => onSetCode(6, e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    
                                </FormGroup>
                            </CardBody>

                            <CardBody className="bg-secondary">
                                <p className="text-sm mb-0">Didn't receive a code? <b className="text-warning cursor-pointer" onClick={onResend}>Resend It</b></p>
                            </CardBody>

                            {err ? (
                                <CardFooter>
                                    <p className="text-sm text-danger mb-0">{err}</p>
                                </CardFooter>
                            ) : null}

                            <CardFooter className="text-right">
                                <button onClick={() => onVerify(code)} className="btn btn-outline-success">Confirm</button>
                            </CardFooter>

                        </Card>
                    )}
                   
                    {!loading ? (
                        <div className="text-center mt-3">
                            <p className="text-sm font-weight-bold">
                                Lost your MFA Method?{' '}
                                <a href="mailto:john@architeck.io?subject=Lost MFA Method&body=Please add your company name, email address/phone number, and current MFA methods on your account.">Contact Us</a>
                            </p>
                        </div>
                    ) : null}

                </Col>
            </Row>
        </Container>
        </>
    )
    
}

export default LoginCompanyMFA;