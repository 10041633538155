import { connect } from 'react-redux';

const AlertNumber = ({contact_alerts}) => <span>{contact_alerts.filter(a => !a.resolved).length}</span>

const mapStateToProps = state => {
	return {
        contact_alerts: state.system.contact_alerts,
	};
};


export default connect(mapStateToProps, '')(AlertNumber);
