import Banned from '../pages/Banned';
import Sign from '../pages/Sign';
import OptOut from '../pages/OptOut';
import TextReply from '../pages/TextReply';


const routes = [
    {
        path: "/banned",
        component: Banned,
    },
    {
        path: "/sign/:template_doc_sent_id",
        component: Sign,
    },
    {
        path: "/opt_out/:identifier",
        component: OptOut,
    },
    {
        path: "/texts/reply/:_id/:email",
        component: TextReply,
    },

]

export default routes;